<template>
    <div :key="service.id" class="border-checkbox-group border-checkbox-group-primary "
        style="width: 100%;padding-bottom: 3px">
        <div>
            <input type="checkbox" class="border-checkbox" :disabled="isRequiredParent" :id="`service_${service.id}`" v-model="payload"
                @change="toggleService">

            <label :for="`service_${service.id}`" class="border-checkbox-label checkboxFlex">
                <div style="width: 12rem">{{ service.name }}
                    <span class="coast" v-if="servicePrice > 0"> ({{ servicePrice }}{{ $t('servicesSelect.c') }})</span>
                </div>
            </label>
        </div>
        <div v-for="(option, k) in service.request_options" :key="k" class="p-l-10" v-if="isSettedOptions && service.request_options.length && param !== 'mass_isdn' && group === param">
            <input type="checkbox" :disabled="!option.required && !payload" class="border-checkbox" :id="`option_${option.name}`" v-model="requestOptions[k].value"
                @change="pushOptions"    
            >

            <label :for="`option_${option.name}`" class="border-checkbox-label checkboxFlex">
                <div style="width: 12rem">{{ $t(`request_options.${option.name}`) }} <span class="coast" v-if="!isUndefined(optionsPrice[option.name]) && optionsPrice[option.name].price > 0">({{ optionsPrice[option.name].price }}{{ $t('servicesSelect.c') }})</span>
                </div>
            </label>
        </div>
        
    </div>
</template>
<script>
import _ from 'underscore';
export default {
    props: ['service', 'isChecked', 'param', 'group', 'iterative'],
    setup(){
        return {
            isUndefined: _.isUndefined
        }
    },
    data() {
        return {
            payload: false,
            isSettedOptions: false,
            requestOptions: [],
            serviceOptions: undefined
        }
    },
    computed: {
        isRequiredParent() {
            return this.service.request_options.some((option) => option.required) 
                && this.onlyRequiredOptions.every(option => !option.value)
        },
        onlyRequiredOptions() {
            return this.requestOptions.filter((option) => option.required)
        },
        optionsPrice() {
            if(this.service.price_options) {
                return _.indexBy(this.service.price_options, 'name')
            }
        },
        servicePrice() {
            return this.service.offline_result_price > this.service.online_result_price ? this.service.offline_result_price : this.service.online_result_price
        }
    },
    methods: {
        toggleService() {
            this.$emit('toggleService', {value: this.payload, service: this.service.name})
            
            if(this.payload) {
                this.pushOptions()
            } else {
                this.removeRequiredOptions()
            }
        },
        setRequestOptions(){
            this.requestOptions = this.service.request_options.map((option) => {
                return {
                    required: option.required,
                    name: option.name,
                    value: false,
                    price: this.optionsPrice[option.name] ? this.optionsPrice[option.name].price : 0
                }
            })
            this.isSettedOptions = true;
        },
        pushOptions() {
            const payloadOptions = {};
            payloadOptions[this.service.name] = {request_options: {}};
            let price = 0;
            for(const option of this.requestOptions) {
                if(option.value) {
                    payloadOptions[this.service.name]['request_options'][option.name] = option.value
                    price += option.price
                }
            }
            let cond = true;
            if(this.onlyRequiredOptions.length) {
                cond = this.onlyRequiredOptions.some((option) => option.value);
                this.payload = cond;
            }
            if(!cond) {
                this.requestOptions.forEach((option) => {
                    option.value = false
                })
            }
            if(this.payload) {
                price += this.servicePrice
            }
            this.$emit('toggleService', {value: this.payload, service: this.service.name})
            const priceObject = {};
            priceObject[this.service.name] = price;
            this.$emit('pushPrice', priceObject)
            setTimeout(() => {
                if(this.payload && this.requestOptions.length) {
                    this.$emit('pushOptions', payloadOptions)
                }
            }, 100)
        },
        setChecked(val) {
            if(!this.isRequiredParent) {
                this.payload = val;
                if(!val && this.requestOptions.length) {
                    this.removeRequiredOptions()
                }
            }
            this.$emit('toggleService', {value: this.payload, service: this.service.name})
        },
        removeRequiredOptions() {
            this.requestOptions.forEach((option) => {
                option.value = false
            })
            this.pushOptions()
        }
    },
    async mounted() {
        this.setChecked(this.isChecked);
        await this.setRequestOptions();
        this.pushOptions();
    },
    watch: {
        requestOptions: {
            handler(val) {
                if(this.isRequiredParent) {
                    this.payload = false;
                }
            },
            deep: true
        },
        isChecked(val) {
            this.setChecked(val);
            this.pushOptions();
        }
    }
}
</script>