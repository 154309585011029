<template>
  <div class="list-group-item info-block-contact">
    <div>
      <span class="mapTooltipFontSize">
        {{ $t('params.' + dataParam.param) | paramName }}:
        <span v-if="(dataParam.param === 'status' || dataParam.param === 'subscriber_status')"> {{ $t(`hlr.${dataParam.value}`) }} </span>
        <span v-else-if="dataParam.param === 'roaming'">{{$t(`params.roaming_${dataParam.value}`)}} </span>
        <span v-else-if="dataParam.param === 'last_activity'">{{moment(dataParam.value, 'X').format('DD.MM.YYYY HH:mm:ss') }}</span>
        <span class="emoji" v-html="$options.filters.isLink($options.filters.makeEmoji(dataParam.value.toString()))"
              v-else-if="dataParam.param !== 'last_registration_time' && dataParam.param !== 'name'"></span>
        <span v-else-if="dataParam.param === 'name'">{{ dataParam.value }}</span>

        <span v-else>{{moment(dataParam.value, 'X').format('DD.MM.YYYY HH:mm:ss') }}</span>
        <small v-if="dataParam.date"> (обновлено:   {{moment(dataParam.date, 'X').format('DD.MM.YYYY HH:mm:ss') }} )</small>
        <span>{{ addressLocation }}</span>
      </span>

    </div>

  </div>
</template>

<script>
import filters from "@/mixins/filters";
import _ from "underscore";
import moment from "moment";

export default {
  props: ['dataParam','parentItems'],
  mixins: [filters],
  name: "infoBlockMapTooltip",
  setup() {
    return {
      moment
    }
  },
  data() {
    return {
      addressLocation: '',
      location: null
    }
  },
  mounted() {
    this.setAddressLocation();
  },
  methods: {
    setAddressLocation() {
      if(!_.isUndefined(this.parentItems) && ['address', 'approximate_address'].includes(this.dataParam.param)) {
        const addresses = this.parentItems.filter(item => ['address', 'approximate_address'].includes(item.param));
        const lats = _.where(this.parentItems, {param: 'latitude'});
        const longs = _.where(this.parentItems, {param: 'longitude'});
        const indexAdress = _.findIndex(addresses, {value: this.dataParam.value});
        if(lats.length && longs.length && lats[indexAdress].value !== '-') {
          this.addressLocation = ` (${parseFloat(lats[indexAdress].value).toFixed(4)}, ${parseFloat(longs[indexAdress].value).toFixed(4)})`;
          // const translit = (new CyrillicToTranslit({preset: 'uk'})).transform(this.dataParam.value, '_').toLowerCase();
          // console.log(translit);
          this.location = [parseFloat(lats[indexAdress].value), parseFloat(longs[indexAdress].value)]
        }
      }
    },
  },
}
</script>

<style>
.mapTooltipFontSize{
  font-size: 14px;
}
</style>