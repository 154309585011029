import axios from "@/configs/axios";
import { isUndefined } from "underscore";
import circleToPolygon from "circle-to-polygon";
import {stringify} from "wkt";
const state = {
    results: []
};

const getters = {
    
};

const actions = {
    getMapImage({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const data = {
                center: `POINT(${payload.longitude} ${payload.latitude})`,
                zoom: payload.zoom || 10,
                width: payload.width || 260,
                height: payload.height || 260,
                markers: [
                    `POINT(${payload.longitude} ${payload.latitude})`
                ]
            };
            if(!isUndefined(payload.accuracy) && payload.zoom >= 10) {
                data.geometries = [];
                const circleTo = circleToPolygon([payload.longitude, payload.latitude], payload.accuracy.to, 128);
                const circleFrom = circleToPolygon([payload.longitude, payload.latitude], payload.accuracy.from, 128);
                const circleToWKT = stringify(circleTo);
                const circleFromWKT = stringify(circleFrom);
                data.geometries.push({
                    geometry: circleToWKT,
                    fill_color: '#0000FFDD'
                });
                data.geometries.push({
                    geometry: circleFromWKT,
                    fill_color: '#FF0000DD'
                });
                // data.geometries.push({
                //     type: 'circle',
                //     center: `POINT(${payload.longitude} ${payload.latitude})`,
                //     radius: payload.accuracy.to,
                //     fill_color: '#3572EF'
                // });
                // data.geometries.push({
                //     type: 'circle',
                //     center: `POINT(${payload.longitude} ${payload.latitude})`,
                //     radius: payload.accuracy.from,
                //     fill_color: '#ef3535',
                // });
            }
            axios({method: 'POST', url: '/map/render', data: data, responseType: 'blob'})
                .then(resp => {
                    const reader = new FileReader();
                    reader.onload = function() {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(resp.data);
                }).catch(err => {
                    reject(err);
                });
        });
    }
};

const mutations = {
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};