<template>
    <li class="header-notification">
        <div class="dropdown-primary dropdown">
            <div class="dropdown-toggle" data-bs-toggle="dropdown">
                <i class="feather icon-bell"></i>
                <span class="badge bg-c-red" v-if="totalCount">{{ totalCount }}</span>
            </div>
            <ul class="show-notification notification-view dropdown-menu" data-dropdown-in="fadeIn"
                data-dropdown-out="fadeOut">
                <li v-for="notification in onlineNotifications" @click="openDetail(notification)">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                            
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="notification-user">{{$t(notification.translation)}}{{ notification.title }}</h5>
                            <span class="notification-time">{{ notification.time }}</span>
                        </div>
                    </div>
                </li>
                <li v-if="notificationAreasCount" @click="openDetail({type: 'area'})">
                    <div class="d-flex">
                        <div class="flex-shrink-0">

                        </div>
                        <div class="flex-grow-1">
                            <h5 class="notification-user">{{ $t('monitoringLocation.new_events_on_areas') }} <span
                                    class="badge badge-danger">{{ notificationAreasCount }}</span></h5>
                        </div>
                    </div>
                </li>
                <li v-if="notificationCrossTrackingTasksCount" @click="openDetail({type: 'crossTracking'})">
                    <div class="d-flex" >
                        <div class="flex-shrink-0">

                        </div>
                        <div class="flex-grow-1">
                            <h5 class="notification-user">{{ $t('monitoringLocation.new_intersections') }} <span
                                    class="badge badge-danger">{{
                                    notificationCrossTrackingTasksCount }}</span></h5>
                        </div>
                    </div>
                </li>
                <li v-if="totalCount === 0">
                    <h5 class="notification-user">{{ $t('monitoringLocation.notification_list_is_empty') }}</h5>
                </li>
            </ul>
        </div>
    </li>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "underscore";
import {EventBus} from "@/eventBus";

export default {
    name: "NotificationHeader",
    data() {
        return {
            onlineCount: 0,
            onlineNotifications: [
                // {
                //     title: "Новое событие по тривожной зоне bobota",
                //     taskId: 18,
                //     time: moment().format('HH:mm'),
                //     type: 'area'
                // },
                // {
                //     title: "New intersection 1, 2",
                //     taskId: 13,
                //     time: moment().format('HH:mm'),
                //     type: 'crossTracking'
                // },
            ],
        }
    },
    created() {
        EventBus.$on('updateSubscribe', this.initializeMonitoringEvents);
    },
    computed: {
        ...mapGetters('locationMonitoring', ['watchedAreas', 'crossTrackingTasks', 'notificationAreasCount', 'notificationCrossTrackingTasksCount']),
        ...mapGetters('monitoring', ['targets']),
        totalCount() {
            return this.notificationAreasCount + this.notificationCrossTrackingTasksCount;
        }
    },
    methods: {
        initializeMonitoringEvents() {           
            this.$echo.connector.options.auth.headers['Authorization'] = localStorage.getItem("token");
            this.$store.dispatch("locationMonitoring/getWatchedAreas")
                .then((areas) => {
                    for (let area of areas) {
                        this.$echo
                            .private(`area-tracking.${area.id}`)
                            .listen('.areaTrackingAlert', (e) => {
                                this.saveAreaNotification(e.data);
                            });
                    }
                    this.dispatchInfoAreas(areas);
                });
            this.$store.dispatch("locationMonitoring/getCrossTrackingTasks")
                .then((places) => {
                    for (let place of places.data.data) {
                        this.$echo
                            .private(`cross-tracking.${place.id}`)
                            .listen('.crossTrackingAlert', (e) => {
                                this.saveCrossTrackingNotification(e.data);
                            });
                    }
                    this.dispatchInfoCrossTrackingTasks(places);
                });
        },
        dispatchInfoAreas(areas) {
            if (!areas.length) return;
            const filters = { task_id: areas.map(area => area.id) }
            if(localStorage.getItem("lastWatchedAreasLogsTime")) {
                filters.created_from = parseInt(localStorage.getItem("lastWatchedAreasLogsTime"));
            }
            this.$store.dispatch("locationMonitoring/getNotificationAreas", {
                filters
            });
        },
        dispatchInfoCrossTrackingTasks(places) {
            if (!places.data.data.length) return;
            const filters = { task_id: places.data.data.map(place => place.id) }
            if(localStorage.getItem("lastWatchedIntersectionsLogsTime")) {
                filters.created_from = parseInt(localStorage.getItem("lastWatchedIntersectionsLogsTime"));
            }
            this.$store.dispatch("locationMonitoring/getNotificationCrossTrackingTasks", {
                filters
            });
        },
        addOnlineNotification(notification) {
            this.onlineNotifications = [notification, ...this.onlineNotifications];
        },
        getAreaInfo(taskId) {
            return _.find(this.watchedAreas, { id: taskId });
        },
        getCrossTrackingTaskInfo(taskId) {
            return _.find(this.crossTrackingTasks, { id: taskId });
        },
        saveAreaNotification(data) {
            const area = this.getAreaInfo(data.task_id);
            const notification = {
                translation: 'monitoringLocation.new_event_on_area',
                title: ' ' + area.title + ': ' + this.getTargetAlias(data.target_id),
                targetId: data.target_id,
                taskId: area.id,
                time: moment(data.log_created_at, 'X').format('HH:mm'),
                timeRaw: data.log_created_at,
                type: 'area'
            };
            const doubleNotification = this.onlineNotifications.find(n => n.taskId === area.id && n.type === 'area' && n.timeRaw === notification.timeRaw && n.targetId === data.target_id);
            if(!_.isUndefined(doubleNotification)) return;
            this.addOnlineNotification(notification);
            this.onlineCount++;
            this.$store.commit("locationMonitoring/setNotificationAreasCount", this.notificationAreasCount + 1);
        },
        getTargetAlias(targetId) {
            const target = this.targets.find(target => target.target_id === targetId)
            return _.isUndefined(target) ? '': target.alias;
        },
        saveCrossTrackingNotification(data) {
            const task = this.getCrossTrackingTaskInfo(data.task_id);
            const notification = {
                translation: 'monitoringLocation.new_intersection',
                title: ': ' + task.targets.map(target => target.alias).join(', '),
                taskId: task.id,
                time: moment(data.log_created_at, 'X').format('HH:mm'),
                timeRaw: data.log_created_at,
                type: 'crossTracking'
            };
            const doubleNotification = this.onlineNotifications.find(n => n.taskId === task.id && n.type === 'crossTracking' && n.timeRaw === notification.timeRaw);
            if(!_.isUndefined(doubleNotification)) return;
            this.addOnlineNotification(notification);
            this.onlineCount++;
            this.$store.commit("locationMonitoring/setNotificationCrossTrackingTasksCount", this.notificationCrossTrackingTasksCount + 1);
        },
        async openDetail(notification) {
            await this.$store.dispatch('locationMonitoring/openNotificationDetail', notification);
            if(this.$route.name !== 'MonitoringLocation') {
                await this.$router.push({ name: 'MonitoringLocation'});
            }
            this.onlineNotifications = this.onlineNotifications.filter(n => n.taskId !== notification.taskId);
            this.onlineCount--;
            EventBus.$emit('notificationMonitoringAction', notification);
        }
    },
    mounted() {
        this.initializeMonitoringEvents();
    },
    watch: {
        '$route.name'(val) {
            if (['monitoringLocation', 'Home'].includes(val)) {
                this.initializeMonitoringEvents();
            }
        }
    }
}
</script>
<style>
.header-notification .icon-bell {
    color: white;
}

body .header-navbar .navbar-wrapper .navbar-container .badge {
    padding: 0;
    line-height: 19px;
    text-align: center;
}

body .header-navbar .navbar-wrapper .navbar-container .notification-user .badge {
    right: auto;
    margin-left: 5px;
}
</style>