<template>
  <div class="tree-node">
    <div class="border p-10 m-b-10">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center">
          <image-profile
              size="30px"
              v-if="comment.author.photos.length"
              :payload="comment.author.photos[0].image"
          />
          <img
              v-if="!comment.author.photos.length"
              width="30"
              :src="`${prefix}images/no_photo_` + `${$root.$i18n.locale}` + `.png`"
              alt="no_photo"
          />
          <div class="fw-bold mr-auto m-l-10 m-r-10">
            {{ getFieldInArray(comment.author.data, 'username, name') }}
          </div>
        </div>
        <div class="fst-italic text-muted">
          <span>{{ $t(`comment_search.contentType.${comment.type}`) }}</span>
          {{ comment.date | moment('DD.MM.YYYY') }} {{ comment.date | moment('HH:mm') }}
        </div>
      </div>
      <div class="m-t-10">
        {{ comment.text }}
      </div>
    </div>

    <div v-if="comment.children && comment.children.length" class="node-children">
      <CommentTree
          v-for="child in comment.children"
          :key="child.id"
          :comment="child"
      />
    </div>
  </div>
</template>

<script>
import ImageProfile from "@/components/ImageProfile.vue";
import env from "@/configs/env.json";

export default {
  name: "CommentTree",
  components: {ImageProfile},

  props: {
    comment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      prefix: env.prefix,
    }
  },

  methods: {
    getFieldInArray(array, field) {
      const fieldList = field.split(',').map(field => field.trim());
      for (const field of fieldList) {
        const foundItem = array.find(item => item.param === field);
        if (foundItem) {
          return foundItem.value;
        }
      }
      return '';
    },
  }
}
</script>

<style scoped>

</style>