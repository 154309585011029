import axios from '../../configs/axios'
import countries from '../../configs/countries.json';
import env from '../../configs/env.json';
import Dexie from 'dexie';
const domain = env.statisticEndpoint;

let host = '';
if(location.hostname.includes('web')) {
    host = location.hostname.replace('web.', '')
    env.monitoringEndpoint = `https://monitoring.${host}/api`
}

const domainMonitoring = env.monitoringEndpoint;

function getTableSize(db, tableName) {
    return new Promise((resolve, reject) => {
        let size = 0;
        db.table(tableName).each(record => {
            size += JSON.stringify(record).length;
        }).then(() => {
            resolve(size);
        }).catch(error => {
            reject("Error fetching data from table " + tableName + ": " + error);
        });
    });
}

// Функція для отримання розміру бази даних
function getDatabaseSize(dbName) {
    return new Promise(resolve => {
        const db = new Dexie(dbName);
        let totalSize = 0;
        db.open().then(() => {
            const tableNames = db.tables.map(table => table.name);
            const tableSizePromises = tableNames.map(tableName => {
                return getTableSize(db, tableName);
            });
            Promise.all(tableSizePromises).then(sizes => {
                totalSize = sizes.reduce((acc, val) => acc + val, 0);
                resolve(totalSize);
            }).catch(error => {
                console.error("Error fetching data from tables: ", error);
                resolve(0);
            }).finally(() => {
                db.close();
            });
        }).catch(error => {
            resolve(0);
        });
    });
}
  
  function humanReadableSize(bytes) {
    var thresh = 1024;
    if(Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    var units = ['KB','MB','GB','TB','PB','EB','ZB','YB'];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
  }
  
  function getFullSize() {
    return new Promise(resolve => {
      const bases = ['map', 'gds', 'Results'];
      const promisesBases = bases.map(item => getDatabaseSize(item));
    
      Promise.all(promisesBases)
        .then(sizes => {
          const total = sizes.reduce(function(acc, val) {
            return acc + val;
          }, 0);
          resolve(total)
        })
    })
  }



const state = {
    searchModal: false,
    modals: {},
    country: localStorage.getItem('country') ? JSON.parse(localStorage.getItem('country')) : {},
    description: "",
    descriptionBases: {
        data: []
    },
    serverChangelog: "",
    webChangelog: "",
    countries,
    keys: [],
    services: JSON.parse(localStorage.getItem('services')) || [],
    notifications: [],
    freeSpace: 5120,
    totalSize: 0
};

const getters = {
    country: state => state.country,
    countries: state => state.countries,
    services: state => state.services,
    sources: state => state.keys,
    notifications: state => state.notifications,
    disallowFreeSpace: state => 0,
    percentTotal: state => 100,
    totalSize: state => state.totalSize,
    servicesNames: state => state.services.map(service => service.name),
    sourcesNames: state => state.descriptionBases.data.map(source => source.name),
};

const mutations = {
    setModalStatuses(state, payload) {
        state.modals[payload.name] = payload.status
    },
    setDescription(state, payload) {
        state.description = payload;
    },
    setDescriptionBases(state, payload) {
        state.descriptionBases = payload;
    },
    // setServerChangelog(state, payload) {
    //     state.serverChangelog = payload;
    // },
    // setWebChangelog(state, payload){
    //     state.webChangelog = payload;
    // },
    setKeysList(state, payload) {
        state.keys = payload;
    },
    setServices(state, payload) {
        state.services = payload;
        localStorage.setItem('services', JSON.stringify(state.services));
    },
    setChangelog(state, payload) {
        let web = payload.data.filter(el => el.app === 'Web Client')
        let server = payload.data.filter(el => el.app === 'GDS Server')
        web = web.sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
        server = server.sort(function (a, b) { return new Date(b.date) - new Date(a.date) })
        state.webChangelog = web;
        state.serverChangelog = server;
    },
    setNotifications(state, payload) {
        state.notifications = payload;
    },
    setFreeSpace(state, size) {
        state.freeSpace = size;
    },
    setTotalSize(state, size) {
        state.totalSize = size;
    },
};
const actions = {
    modalControl({ commit }, payload) {
        commit('setModalStatuses', payload)
    },
    getDescription({ commit }, locale) {
        return new Promise(resolve => {
            axios.post('services', {}, { headers: { locale } })
                .then(resp => {
                    commit('setDescription', resp.data)
                    resolve(resp.data)
                })
        })
    },
    getDescriptionBases({ commit }) {
        return new Promise(resolve => {
            axios.post('sources', { order_type: 'desc' })
                .then(resp => {
                    commit('setDescriptionBases', resp.data)
                    resolve(resp.data)
                })
        })
    },
    getDescriptionMonitoring({ commit }, locale) {
        return new Promise(resolve => {
            axios({baseURL: domainMonitoring, method: 'POST', url: 'services', headers: { locale } })
                .then(resp => {
                    console.log(resp.data);
                    
                    resolve(resp.data)
                })
        })
    },
    acceptNotification(context, id) {
        return new Promise(resolve => {
            axios.get(`notification/${id}/accept`)
                .then(resp => {
                    resolve(resp.data)
                })
        })
    },
    // getServerChangelog({commit}) {
    //     return new Promise(resolve => {
    //         axios.get('info/changelog/server')
    //             .then(resp => {
    //                 commit('setServerChangelog', resp.data)
    //                 resolve(resp.data)
    //             })
    //     })
    // },
    // getWebChangelog({commit}){
    //     return new Promise(resolve => {
    //         axios.get('info/changelog/web')
    //             .then(resp => {
    //                 commit('setWebChangelog', resp.data)
    //                 resolve(resp.data)
    //             })
    //     })
    // },


    getChangelog({ commit }, locale) {
        let payload = {
            // order_by: 'created_at',
            filters: {
                app: ['GDS Server', 'Web Client']
            },
        }
        return new Promise(resolve => {
            axios({
                url: 'changelog', method: 'POST', data: payload, headers: { locale }
            })
                .then(resp => {
                    commit('setChangelog', resp.data)
                })
        })
    },


    getServices({ commit }) {
        return new Promise(resolve => {
            axios.post('services')
                .then(resp => {
                    commit('setServices', resp.data.data);
                    resolve(resp.data.data);
                });
        });
    },
    getKeys({ commit }) {
        const api = axios;
        return new Promise(resolve => {
            resolve(true);
            api({ url: domain + '/api/software/sources', method: 'POST' })
                .then(resp => {
                    commit('setKeysList', resp.data.data);
                    resolve(resp.data.data);
                })
        })
    },
    getNotifications({ commit }) {
        return new Promise(resolve => {
            axios({
                url: 'notifications', method: 'GET', data: {
                    order_by: 'created_at',
                    order_type: 'desc'
                }
            })
                .then(resp => {
                    commit('setNotifications', resp.data.data);
                    resolve(resp.data.data);
                })
        })
    },
    async getFreeSpaceInfo({ commit }) {
        const total = await getFullSize();
        await commit('setTotalSize', humanReadableSize(total));
    }
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}