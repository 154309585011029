<template>
  <div class="d-flex flex-row-reverse">
    <button class="btn btn-primary m-r-10 m-b-10" @click="$bvModal.show(`telegram-group-${recordId}`)">
      {{ $t('ui.detail') }}
    </button>

    <b-modal
        content-class="h-100"
        size="lg" scrollable
        :id="`telegram-group-${recordId}`"
        centered
        hide-footer
        @hidden="closeModal"
        @show="openModal"
    >
      <template #modal-title>
        <span>Telegram Monitor: {{ username }}</span>
      </template>

      <div class="main-tabs">
        <b-tabs
            content-class="p-10"
            v-if="isLoaded && telegramComment && !isModelExistRecord"
        >
          <b-tab :title="$t('comment_search.group')" active>
            <div class="row">
              <div>{{ $t(`comment_search.showPeriod`) }}:</div>
              <b-col cols="12" md="4">
                <date-picker
                    v-model="selectedPeriodFrom"
                    type="date"
                    format="DD.MM.YYYY"
                    lang="ua"
                    :editable="false" />
              </b-col>
              <b-col cols="12" md="4">
                <date-picker
                    v-model="selectedPeriodTo"
                    type="date"
                    format="DD.MM.YYYY"
                    :editable="false" />
              </b-col>
              <b-col>
                <button class="btn btn-primary w-100" @click="getCountComments">
                  {{ $t(`comment_search.search`) }}
                </button>
              </b-col>
            </div>
          </b-tab>
          <b-tab :title="$t('comment_search.quickSearch')">
            <div class="row">
              <div>{{ $t(`comment_search.showPeriod`) }}:</div>
              <b-col>
                <b-form-input
                    :placeholder="$t(`comment_search.searchQuery`)"
                    v-model="searchQuery"
                />
              </b-col>
              <b-col>
                <b-form-input
                    :placeholder="$t(`comment_search.groupName`)"
                    v-model="groupName"
                />
              </b-col>
              <b-col>
                <button class="btn btn-primary w-100" @click="getCountComments">
                  {{ $t(`comment_search.search`) }}
                </button>
              </b-col>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div v-if="!isLoaded && isViewSkeleton">
        <div class="m-t-20 m-b-20">
          <b-skeleton width="200px"/>
          <b-skeleton width="300px"/>
        </div>

        <b-skeleton-table
            :rows="1"
            :columns="3"
            :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
      </div>

      <div v-if="isLoaded && !telegramComment" class="m-t-20">
        <div>{{ $t(`comment_search.countComments`) }}: 0</div>
      </div>

      <div
          v-if="isLoaded && telegramComment && !isModelExistRecord"
          class="m-t-20">
        <div v-for="comment in telegramComment.search_results" :key="comment.id">
          <div>{{ $t(`comment_search.countComments`) }}: {{ comment.count }}</div>
          <div>
            <table class="table table-hover table-responsive-sm">
              <thead>
              <th>{{ $t('ui.group') }}</th>
              <th>{{ $t('ui.period') }}</th>
              <th>{{ $t('ui.count') }}</th>
              </thead>
              <tbody>
              <tr v-for="group in comment.groups" :key="group.id">
                <td>
                  <div class="d-flex">
                    <div class="col-12 col-lg-3 border-checkbox-section">
                      <div class="border-checkbox-group border-checkbox-group-primary">
                        <input
                            type="checkbox"
                            class="border-checkbox"
                            :id="'checkbox-' + group.id" v-model="groups"
                            :key="group.id" :value="{'id': group.id,'name': getGroupName(group.id),'count': group.count}"
                        >
                        <label
                            :for="'checkbox-' + group.id"
                            class="border-checkbox-label"
                        >
                          {{ getGroupName(group.id) }}
                        </label>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{ setDateFormat(comment.period_from) }} - {{ setDateFormat(comment.period_to) }}</td>
                <td>{{ group.count }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        {{ $t(`comment_search.countSelectedGroup`) }}: {{ groups.length }}

        <div class="d-flex justify-content-center">
          <span id="tooltip-button-variant" class="w-25">
            <b-button
                class="w-100"
                variant="primary"
                :disabled="!groups.length"
                v-b-modal.modal-multi-3
            >
              {{ $t('search.open') }}
            </b-button>
          </span>
          <b-tooltip
              v-if="!groups.length"
              target="tooltip-button-variant"
              variant="danger"
              triggers="hover"
              position="right"
              title="You must select at least one group"
          />
        </div>
      </div>

      <div v-if="isLoaded && !telegramComment" class="d-flex justify-content-center m-t-20 bg-light">
        {{ $t('result.nothing_found') }}
      </div>
    </b-modal>

    <comment-main-search :data="searchData" :groups="groups"/>

    <b-modal v-model="isModelExistRecord" centered :title="$t('search.query_exist')" hide-footer has-modal-card
             trap-focus :destroy-on-hide="false" aria-role="dialog"
             aria-label="Example Modal" aria-modal
             @hidden="closeWarnModal">
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            {{ $t(`comment_search.periodFrom`) }}:
            {{ moment(selectedPeriodFrom, 'X').format('DD MMM YYYY') }} - {{ moment(selectedPeriodTo, 'X').format('DD MMM YYYY') }}
            <b-button variant="primary" class="mt-3" @click="remoteComments()">{{ $t('search.update') }}</b-button>
            <b-button variant="secondary" class="mt-2" @click="localComments()">{{ $t('search.open') }}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import CommentMainSearch from "@/components/search/commentMainSearch.vue";
import {Result} from "@/models/result";
import telegramMonitor from "@/mixins/telegramMonitor";

export default {
  name: "SearchComment",

  mixins: [telegramMonitor],

  setup() {
    return {
      moment
    }
  },

  components: {
    CommentMainSearch,
    DatePicker
  },

  props: ['recordId'],

  data() {
    return {
      visible: false,
      isModelExistRecord: false,
      isLoaded: false,
      isViewSkeleton: false,
      firstSearch: true,
      selectedPeriodFrom: '',
      selectedPeriodTo: '',
      viewSelectedPeriodFrom: '',
      viewSelectedPeriodTo: '',
      groups: [],
      searchQuery: '',
      groupName: '',
      searchData: {},
      username: '',
    }
  },

  computed: {
    ...mapGetters('search_comment', ['telegramComment'])
  },

  methods: {
    ...mapMutations('search_comment', ['setClearTelegramComments']),

    async getCountComments() {
      await this.getUsername();
      this.searchData = this.prepareBodyRequest();

      let childKey = this.generatePreviewDbKeyWithParent(this.searchData, 'preview')

      let response = await this.$store.dispatch('search_comment/getLocalTelegramGroupComments', childKey)

      if (!Object.keys(response).length) {
        this.groups = [];
        this.clearData();

        await this.remoteComments()
      }

      if (Object.keys(response).length) {
        this.isModelExistRecord = true;

        if(response.meta.payload.data_request) {
          this.selectedPeriodFrom = new Date(moment.unix(response.meta.payload.data_request.period_from))
          this.selectedPeriodTo = new Date(moment.unix(response.meta.payload.data_request.period_to))
        }
      }
    },

    async getUsername() {
      let data = await Result.getResult(Number(this.$route.params.id));
      const dataInfo = data.result.results.filter(item => item.source === 'Telegram_scr_2023').shift();

      this.username = Object.values(dataInfo.search_results)
          .flatMap(result => result.data).find(item => item.param === "username")?.value || null;

      if (this.username === null) {
        this.username = dataInfo.value
      }
    },

    prepareBodyRequest() {
      const data = {
        'author': {'param': 'username', 'value': this.username},
      }

      if (this.groupName) data['group'] = {"param": "name", "value": this.groupName};
      if (this.searchQuery) data['search_query'] = this.searchQuery;

      if (this.selectedPeriodFrom) {
        this.firstSearch = false;
        data['period_from'] = moment(this.selectedPeriodFrom).format("X");
      }

      if (this.selectedPeriodTo) {
        this.firstSearch = false;
        data['period_to'] = moment(this.selectedPeriodTo).format("X");
      }

      return data;
    },

    localComments() {
      this.isModelExistRecord = false;
      this.isViewSkeleton = false;
      this.isLoaded = true;
    },

    async remoteComments() {
      let childKey = this.generatePreviewDbKeyWithParent(this.searchData, 'preview')
      await Result.deleteResult(childKey);

      this.isModelExistRecord = false;
      this.isLoaded = false;
      this.isViewSkeleton = true;

      let dataServer = {... this.searchData};
      if (this.firstSearch) {
        this.selectedPeriodFrom = new Date(moment().subtract(1, 'year').startOf('day'))
        this.selectedPeriodTo = new Date(moment().startOf('day'))

        const dates = {
          period_from: moment().subtract(1, 'year').startOf('day').unix(),
          period_to: moment().startOf('day').unix()
        }
        Object.assign(dataServer, dates);
      }

      let response = await this.$store.dispatch('search_comment/getTelegramComments', dataServer);

      this.isViewSkeleton = false;
      this.isLoaded = true;

      if (!response.length) return;

      let payload = {
        cache_search_enabled: true,
        online_search_enabled: false,
        value: this.searchData.author.value,
        sources: ['Telegram_scr_2023'],
        childKey: this.generatePreviewDbKey(this.searchData, 'preview'),
        data_request: dataServer
      };

      response.find(item => item.param = 'username')

      await this.$store.dispatch('contacts/cacheWithResultsChild', {
        data: response,
        type: 'telegram_monitor_preview',
        parentKey: this.$store.getters["contacts/meta"].key,
        payload
      });

      this.firstSearch = false;
    },

    toCamelCase(code) {
      return code.replace(/([a-zA-Z0-9]+)_([a-zA-Z0-9])/g, (_, prefix, nextChar) => {
        return prefix + nextChar.toUpperCase();
      });
    },

    clearData() {
      this.setClearTelegramComments()
      // this.setClearTelegramGroupComments()
      this.isLoaded = false;
    },

    closeModal() {
      this.clearData();
      Object.assign(this.$data, this.$options.data.call(this));
    },

    openModal() {
      this.getCountComments()
    },

    closeWarnModal() {
      this.localComments();
    },

    getGroupName(groupId) {
      const group = this.telegramComment.relative_results.groups.find(group => group.id === groupId)
      return group.data[0].value;
    },

    setDateFormat(date) {
      moment.locale(this.locale());

      if (moment(date).isValid()) {
        date = moment(date).format("DD MMMM YYYY");
      } else {
        date = moment.unix(date).format("DD MMMM YYYY");
      }

      return date
    },

    locale() {
      return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale
    },
  }
}
</script>

<style scoped>
.main-tabs >>> .comment-tab {
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #37474f;
  border-radius: 0;
  color: #37474f;
  width: 200px;
  text-align: center;
}

.main-tabs >>> .comment-tab.active, .main-tabs >>> .comment-tab.active:hover {
  color: #0275d8;
  text-align: center;
  border-bottom: 2px solid #0275d8;
}
</style>