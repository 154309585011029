import { render, staticRenderFns } from "./movementHistoryResult.vue?vue&type=template&id=baaf1a50"
import script from "./movementHistoryResult.vue?vue&type=script&lang=js"
export * from "./movementHistoryResult.vue?vue&type=script&lang=js"
import style0 from "./movementHistoryResult.vue?vue&type=style&index=0&id=baaf1a50&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports