import https from 'https';
import uuid from '@/helpers/uuid'
import store from "@/store";
import router from "@/router";
import axios from "axios";

import env from './env.json'
let host = '';
if(location.hostname.includes('web')) {
    host = location.hostname.replace('web.', '')
    env.endpoint = `https://${host}/api`
}

import i18n from "@/i18n";

const token = window.localStorage.getItem('token')

// const setupId = window.localStorage.getItem('setupId');
const axiosInstance = axios.create({});
let locale = i18n.locale === 'ua' ? 'uk' : i18n.locale
axiosInstance.defaults.headers.common['locale'] = locale;
if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = token
}
// if (setupId) {
//     axios.defaults.headers.common['Setup-Id'] = setupId
// } else {
//     let randString = uuid();
//     window.localStorage.setItem('setupId', randString);
//     axios.defaults.headers.common['Setup-Id'] = randString;
// }


// axios.defaults.timeout = 180*1000;


// axios.defaults.headers.common['user-agent'] = "GDClient/1.11.0-beta-sandbox-debug (Xiaomi Mi 9T Pro; android 10)";

axiosInstance.defaults.baseURL = env.endpoint;

axiosInstance.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });

const MAX_REQUESTS_COUNT = 3;
const INTERVAL_MS = 50;
let PENDING_REQUESTS = 0;

/**
 * Axios Request Interceptor
 */
axiosInstance.interceptors.request.use(function (config) {
    return new Promise((resolve) => {
        let interval = setInterval(() => {
            if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                PENDING_REQUESTS++
                clearInterval(interval)
                resolve(config)
            }
        }, INTERVAL_MS)
    })
})
/**
 * Axios Response Interceptor
 */
axiosInstance.interceptors.response.use(function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
}, function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    if(error.response.status === 401) {
        store.dispatch('auth/logoutAction')
            .then(() => {
                return router.push({name: 'Login'})
            })
    } else if(error.response.status === 403) {

    }
    return Promise.reject(error)
})
export default axiosInstance;