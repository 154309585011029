import axios from "@/configs/axios";
import {Result} from '@/models/result'

const state = {
    telegramComment: {},
    telegramGroupComments: {}
}
const getters = {
    telegramComment: state => state.telegramComment,
    telegramGroupComments: state => state.telegramGroupComments
}

const actions = {
    async getTelegramComments({commit, dispatch}, data) {
        let result = await axios({url: 'content_search/preview', data: data,  method: 'POST'});

        if (result.status === 200) {
            commit('setTelegramComments', result.data.results)
            return result.data.results;
        }

        return [];
    },

    getTelegramGroupComments({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            axios({url: 'content_search', data: data,  method: 'POST'})
                .then((resp) => {
                    commit('setTelegramGroupComments', resp.data.results)
                    resolve(resp.data.results);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getLocalTelegramGroupComments({commit, dispatch}, childKey) {
        let dbResponse = await Result.getResultByKey(childKey);

        if (typeof dbResponse !== 'undefined') {
            commit('setTelegramComments', dbResponse.result.results);
            return dbResponse.result;
        }

        return {};
    },

    getTelegramGroupCommentsPaginate({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `content_search/request/${data.query.request_id}/source/${data.query.source_id}`, data: data.body,  method: 'POST'})
                .then((resp) => {
                    commit('setLocalTelegramComments', resp.data)
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}

const mutations = {
    setTelegramComments(state, payload = []) {
        state.telegramComment = payload.filter(item => item.source === 'Telegram_scr_2023').shift();
    },

    setClearTelegramComments(state) {
        state.telegramComment = {};
    },

    setClearTelegramGroupComments(state) {
        state.telegramGroupComments = {};
    },

    setTelegramGroupComments(state, payload = []) {
        state.telegramGroupComments = payload.filter(item => item.source === 'Telegram_scr_2023').shift();
    },

    setLocalTelegramComments(state, payload) {
        state.telegramGroupComments = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}