import { render, staticRenderFns } from "./carPlateNumSearch.vue?vue&type=template&id=27b62d56&scoped=true"
import script from "./carPlateNumSearch.vue?vue&type=script&lang=js"
export * from "./carPlateNumSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b62d56",
  null
  
)

export default component.exports