import Vue from 'vue'
import App from './App.vue'
// import Buefy from "buefy";
import router from './router'
// import 'buefy/dist/buefy.css'
import store from './store'
import axios from "./configs/axios";
import { library } from '@fortawesome/fontawesome-svg-core';
import Vuelidate from "vuelidate";
import VueCountryCode from "vue-country-code";
import VueTheMask from 'vue-the-mask'
import AutoReload from '@/plugins/AutoReload';
import VueCrontab from 'vue-crontab';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
let anotherDomain = false;
import env from '@/configs/env.json'
import VueParticles from 'vue-particles'
import VueMask from 'v-mask'
import io from 'socket.io-client/lib/index.js';
// import Echo from 'laravel-echo'; 
import VueEcho from 'vue-echo-laravel';
window.io = io;
Vue.use(VueMask)
// import "@/assets/scss/style.scss";

let host = '';
if(location.hostname.includes('web')) {
  host = location.hostname.replace('web.', '');
  env.analyticsHost = `https://analytics.${host}`;
  env.monitoringEndpoint = `https://monitoring.${host}/api`
}

// var echo_instance = new Echo();

// axios.post(env.analyticsHost+'/matomo.php').then(() => {
//
//
//
// }).catch(e => {
//   if(e.toJSON().status === 400) {
//
//   }
// })

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueTippy, { TippyComponent } from "vue-tippy";

// internal icons
import {
  faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
  faArrowUp, faAngleRight, faAngleLeft, faAngleDown,
  faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faUser, faSyncAlt, faTrashAlt, faLongArrowAltLeft, faLongArrowAltRight,
  faSortUp, faSortDown, faCalendarDay, faImage, faSearchPlus, faSearch, faPlus, faTimes, faFilePdf, faDownload, faEdit,
  faCamera, faCaretSquareDown, faCheckSquare, faRedoAlt, faArrowLeft, faUserPlus, faChevronLeft, faChevronRight, faCrosshairs,
  faBars, faServer, faGlobe, faDatabase,
  faChevronDown, faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import i18n from './i18n'
library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
  faArrowUp, faAngleRight, faAngleLeft, faAngleDown,
  faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faUser, faSyncAlt, faTrashAlt, faLongArrowAltLeft, faLongArrowAltRight,
  faSortDown, faSortUp, faCalendarDay, faImage, faSearchPlus, faSearch, faPlus, faTimes, faFilePdf, faDownload, faEdit,
  faCamera, faCaretSquareDown, faCheckSquare, faRedoAlt, faArrowLeft, faUserPlus, faChevronLeft, faChevronRight, faCrosshairs,
  faBars, faServer, faGlobe, faDatabase, faChevronDown, faChevronUp
);

import VueMatomo from 'vue-matomo'
import { head } from 'underscore';
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.prototype.$http = axios;

Vue.config.productionTip = false

Vue.use(require('vue-moment'));
Vue.use(Vuelidate);
Vue.use(VueCountryCode);
Vue.use(VueTheMask)
Vue.use(VueCrontab);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});
Vue.use(VueTippy);
Vue.use(VueParticles)


Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: env.analyticsHost,
  siteId: 1,
  router
})
localStorage.setItem('rke_params', window.location.search)

Vue.component("tippy", TippyComponent);
Vue.use(VueEcho, {
  broadcaster: 'socket.io',
  host: env.monitoringEndpoint.replace('/api', ''),
  path: '/echo/socket.io',
  transports: ['websocket'],
  headers: {
    Authorization: localStorage.getItem('token')
  }
})

new Vue({
  router,
  store,
  i18n,
  created() {
    Vue.use(AutoReload, {
      config: {
        // модуль включен
        Enabled: true,
        // ежеминутное обновление
        CheckInterval: 60,
      },
      router: this.$router,
      vm: this,
    });
  },
  render: h => h(App)
}).$mount('#app')
