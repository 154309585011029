<template>
  <div class="alert alert-danger alert-dismissible fade show" role="alert"
    :id="`alert_${notification.id}`">
    {{ notificationMessage }}
    <router-link class="link-go" v-if="notification.event === 'monitoring_task_expires:online_status' && isOnlineMonitoring"
      :to="{ 'name': 'Monitoring' }">{{ $t('pages.Monitoring') }} <i class="feather icon-external-link"></i></router-link>
      <router-link class="link-go" v-if="notification.event === 'monitoring_task_expires:location' && isLocationMonitoring"
      :to="{ 'name': 'MonitoringLocation' }">{{ $t('pages.MonitoringLocation') }} <i class="feather icon-external-link"></i></router-link>
      <router-link class="link-go" v-if="notification.event === 'source_access_changed'"
      :to="{ 'name': 'DescriptionBases' }">{{ $t('pages.DescriptionBases') }} <i class="feather icon-external-link"></i></router-link>
      <!-- <div class="dropdown btnCheck" v-if="!notification.repeat">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{ $t('ui.actionsWithMessages') }}
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="#" @click.prevent="acceptNotification(notification.id)"> {{ $t('ui.acceptNotification') }} </a></li>
          <li><a class="dropdown-item" href="#" @click.prevent="closeNotification(notification.id)"> {{ $t('ui.closeNotification') }} </a></li>
        </ul>
      </div> -->
      <div class="btnCheck">
        <button class="btn btn-primary" v-if="notification.repeat" @click.prevent="closeNotification(notification.id)"> {{ $t('ui.Ok') }}</button>
        <button class="btn btn-primary" v-else @click.prevent="acceptNotification(notification.id)"> {{ $t('ui.Ok') }}</button>
      </div>
    <!-- <button type="button"
            class="btn btnCheck"
            v-if="!notification.repeat"
            @click="acceptNotification(notification.id)"
            :content="$t('ui.acceptNotification')"
            v-tippy="{ placement : 'top',  arrow: true }">
      <i class="feather icon-check"></i>
    </button>
    <button type="button"
            class="btn-close"
            @click="closeNotification(notification.id)"
            data-bs-dismiss="alert"
            :content="$t('ui.closeNotification')"
            v-tippy="{ placement : 'top',  arrow: true }"
            aria-label="Close"></button> -->
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import moment from "moment";
export default {
  name: 'NotificationItem',
  props: ['notification'],
  computed: {
    ...mapGetters('auth', ['permissions']),
    ...mapGetters({
      monitoringServices: "serviceMonitoringGroup/customerServices",
    }),
    isLocationMonitoring() {
      return this.monitoringServices.some((service) => service.monitoring_types.includes("location"));
    },
    isOnlineMonitoring() {
      return this.monitoringServices.some((service) => service.monitoring_types.includes("online_status"));
    },
    notificationMessage(){
      let str = this.notification.message
      if (str.includes('{estimated_time}') && typeof this.notification.bindings !== 'undefined' && typeof this.notification.bindings.estimated_time !== 'undefined'){
        let time = moment(this.notification.bindings.estimated_time, "X").format('DD.MM.YYYY HH:mm')
        str = str.replace('{estimated_time}', time)
      }
      return str
    }
  },
  methods: {
    closeNotification(id) {
      this.closeBsAlert(id)
      let notifications = {}
      if (localStorage.getItem('notifications')) {
        notifications = JSON.parse(localStorage.getItem('notifications'));
      }
      notifications[this.notification.id] = moment().format('YYYY-MM-DD');
      localStorage.setItem('notifications', JSON.stringify(notifications));
    },
    closeBsAlert(id) {
      const alertNode = document.getElementById(`alert_${id}`)
      const bsAlert = new bootstrap.Alert(alertNode);
      bsAlert.close();
    },
    acceptNotification(id) {
      this.closeBsAlert(id);
      this.$store.dispatch('ui/acceptNotification', id)
        .then(resp => {
          if (resp.success) {
          }
        })
    }
  }
}
</script>
<style scoped>
.alert {
  font-size: 16px;
  color: var(--bs-table-color);
}

.alert-dismissible .btnCheck {
  position: absolute;
  right: 10px;
  vertical-align: middle;
  top: 10px;
}

body .alert-dismissible {
  padding-right: 80px !important;
}

/* .alert-dismissible .btnCheck i {
  font-size: 18px;
  color: #1fa766;
}

.alert-dismissible .btnCheck i:hover {
  color: #198754;
} */

.link-go {
  text-decoration: underline;
  font-size: 16px;
}

.btnCheck > .btn {
  text-transform: none;
}
</style>
