<template>
  <section class="card" v-if="currentParam">
    <div class="card-body">
        <component :is="forms[currentParam]"></component>
    </div>
  </section>
</template>
<script>
import {mapGetters} from "vuex";
import isdnForm from "./params/isdnForm.vue";
import imageForm from "./params/imageForm.vue"
import itnForm from "@/components/search/params/itnForm";
import nameForm from "@/components/search/params/nameForm";
import emailForm from "@/components/search/params/emailForm";
import usernameForm from "@/components/search/params/usernameForm";
import telegramIdForm from "@/components/search/params/telegramIdForm";
import carPlateNumForm from "@/components/search/params/carPlateNumForm";
import massIsdnForm from "@/components/search/params/massIsdnForm";
import TelegramLocationForm from "@/components/search/params/telegramLocationForm";
import carVinForm from "@/components/search/params/carVinForm";
import isdn_imei_imsi_search from "@/components/search/params/isdnImeiImsiSearch";
import phoneSearchContainer from "@/components/search/params/phoneSearchContainer";
export default {
    name: "MainSearch",
    setup() {
        return {
            forms: {
                // 'isdn': isdnForm,
                'isdn': phoneSearchContainer,
                'imsi': phoneSearchContainer,
                'imei': phoneSearchContainer,
                'mass_isdn': massIsdnForm,
                'name': nameForm,
                'itn': itnForm,
                'image': imageForm,
                'email': emailForm,
                'username': usernameForm,
                'telegram_id': telegramIdForm,
                'car_plate_num': carPlateNumForm,
                'car_vin': carVinForm,
                'geo_search_telegram': TelegramLocationForm,
                'isdn_imei_imsi_search' : isdn_imei_imsi_search,

            }
        }
    },
    computed: {
        ...mapGetters('mainMenuState', ['currentParam']),
    },
    mounted() {
        this.$store.dispatch('ui/getFreeSpaceInfo')
    },
}
</script>