<template>
  <div class="card" style="margin-top: 2rem; border-radius: 5px">
    <div id="map" style="width: 100%; height: 400px;border-radius: 5px"></div>
    <div id="images"></div>
    <div class="mapTooltip" v-if="selectedPin" style="overflow-y: auto">
      <div class="tooltipTitle" style="position: sticky; top: 0">
        <div>{{selectedPin.result.source}}:{{selectedPin.result.value}}</div>
        <i class="feather icon-x" @click="selectedPin = null" :content="$t('monitoringLocation.closeTooltip')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
      </div>
        <div v-for="result in selectedPin.result.results">
          <div class="resultDate" v-if="selectedPin.result.results.length > 1">{{result.source_name}} {{ setCorrectDate(result.info_date) }}</div>
          <div class="resultDate" v-else>{{ setCorrectDate(result.info_date) }}</div>
          <info-block-map-tooltip
              v-if="(!['longitude', 'latitude'].includes(dataObject.param))"
              v-for="dataObject in result.data"
              :parentItems="result.data"
              :dataParam="dataObject"
          />
        </div>


    </div>
  </div>
</template>

<script>
import drawLocales from "leaflet-draw-locales";
import L from "leaflet";
import filters from "@/mixins/filters";
import { LMap, LPolyline, LTileLayer } from "vue2-leaflet";
import LDrawToolbar from "vue2-leaflet-draw-toolbar";
import _ from "underscore"
import { mapGetters } from "vuex";
import "@/plugins/wise-leaflet-pip"
import {SimpleMapScreenshoter} from 'leaflet-simple-map-screenshoter';
import InfoBlockMapTooltip from "@/components/results/infoBlockMapTooltip";
import moment from "moment";

const pinMarker = L.Marker.extend({
  options: {
    targetId: ''
  }
})

const CustomMarker = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 41),
    iconSize: new L.Point(25, 41),
    iconUrl: '/images/marker-icon.png'
  }
});
const CustomMarker2 = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 41),
    iconSize: new L.Point(25, 41),
    iconUrl: '/images/marker-icon-modified.png'
  }
});

export default {
  name: "mapResult",
  mixins: [filters],
  components: {InfoBlockMapTooltip, LMap, LTileLayer, LDrawToolbar },
  props: ['pins', 'location'],
  data() {
    return {
      url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: [60.117188, 51.134555],
      map: null,
      startZoom: 3,
      maxRadius: 100000,
      drawnItems: null,
      bounds: null,
      selectedPin: null,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale;
    },
    ...mapGetters('full_search', ['resultMapCenter'])
  },
  watch: {
    resultMapCenter: {
      deep: true,
      handler(val) {
        this.map.setView(val, 12)
        this.map.eachLayer(element => {
          const pinLocation = new L.LatLng(val[0], val[1])
          if (pinLocation.equals(element._latlng)) {
            element.openTooltip()
            window.scrollTo(0, 0)
          } else {
            element.closeTooltip()
          }
        });
      }
    },
  },

  methods: {
    async initMap() {
      const locale = drawLocales(this.locale)
      L.drawLocal = locale
      this.map = await L.map('map', { zoomControl: false, attributionControl: false, center: this.center, zoom: this.startZoom });
      const tile = L.tileLayer(this.url, { maxZoom: 20, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map);
      L.control.zoom({
        zoomInTitle: this.$t('geo.zoomIn'),
        zoomOutTitle: this.$t('geo.zoomOut')
      }).addTo(this.map);
      L.control.scale({imperial: false, position: 'bottomright'}).addTo(this.map);
      var southWest = L.latLng(-89.98155760646617, -180), 
            northEast = L.latLng(89.99346179538875, 180); 
            var bounds = L.latLngBounds(southWest, northEast); 
            
            this.map.setMaxBounds(bounds); 
            this.map.on('drag', () => { 
                this.map.panInsideBounds(bounds, { animate: false }); 
            });
      L.Circle.include({
        contains: function (latLng) {
          return this.getLatLng().distanceTo(latLng) < this.getRadius();
        }
      });
      this.drawnItems = new L.FeatureGroup().addTo(this.map);
      // tile.on('load', (event) => {
      //     this.setCardImage()
      // });
    },
    async setFigures() {
      if (this.location) {
        for (const figure of this.location) {
          switch (figure.type) {
            case 'circle':
              await this.setCircle(figure);
              break;
            case 'polygon':
              await this.setPolygon(figure);
              break;
            case 'point':
              await this.setPoint(figure);
              break;
            default:
              break;
          }
        }
        this.bounds = this.drawnItems.getBounds();
        this.map.fitBounds(this.bounds);
      }
    },
    async setBounds() {
            return new Promise(resolve => {
              this.map.fitBounds(this.drawnItems.getBounds());
              resolve('seted')
            })
        },
    setCircle(figures) {
      const coordinates = figures.coordinates;
      const circle = L.circle([coordinates[1], coordinates[0]], {
        radius: figures.radius
      })
      this.drawnItems.addLayer(circle);
    },
    setCirclesLocator(){
      this.pins.forEach(pin=> {
        let accuracy = pin.result.results[0].data.find(item => item.param === 'accuracy')
        if (typeof accuracy !== 'undefined'){
          let splitAccuracy = accuracy.value.split('~')
          let minAcc = splitAccuracy[0];
          let maxAcc = splitAccuracy[1].split(' ')[0]
          const coordinates = pin.location;


          const circle2 = L.circle([coordinates[0], coordinates[1]], {
            color: '#3572EF',
            fillColor: '#3572EF',
            fillOpacity: 0.5,
            radius: maxAcc
          })
          this.drawnItems.addLayer(circle2);
          const circle = L.circle([coordinates[0], coordinates[1]], {
            color: '#ef3535',
            fillColor: '#ef3535',

            fillOpacity: 0.5,
            radius: minAcc
          })
          this.drawnItems.addLayer(circle);
        }
      })

    },
    async setPolygon(figures) {
      const geoJSON = {
        "type": "Polygon",
        "coordinates": figures.coordinates
      };
      const polygon = L.geoJSON(geoJSON)
      for (const poly of Object.values(polygon._layers)) {
        this.drawnItems.addLayer(poly);
      }
    },
    setPoint({ coordinates }) {
      // console.log('point');
      // this.center = [coordinates[1], coordinates[0]];
      // const marker = L.marker(this.center, {
      //   icon: new CustomMarker()
      // })
      // marker.addTo(this.map);
      // this.map.setView(this.center, 10)
    },
    setPins() {
      let coords = []
      for (const pin of this.pins) {
        coords.push(pin.location)
        const containsArray = [];
        if(!this.bounds) {
          containsArray.push(true)
        }
        for (const k of Object.keys(this.drawnItems._layers)) {
          const bounds = this.drawnItems._layers[k].getBounds()
          containsArray.push(bounds ? this.drawnItems._layers[k].contains(new L.latLng(pin.location[0], pin.location[1])) : true);
        }
        const contains = _.some(containsArray)
        const title = contains ? pin.title : `${this.$t('geo.in_connection')} ${pin.title}`
        // let marker = new pinMarker(pin.location, { icon: contains ? new CustomMarker() : new CustomMarker2(), targetId: pin.contact, result: pin.result }).addTo(this.map);
        let marker = new pinMarker(pin.location, { icon: contains ? new CustomMarker() : new CustomMarker2(), targetId: pin.contact, pin: pin }).addTo(this.map);
        marker.on('click', (e) => {
          // document.getElementById(e.target.options.targetId).scrollIntoView({ block: "start", behavior: "smooth" });
          this.pinClickAction(e)
        });
        marker.bindTooltip(title)
      }
      const wellBounds = new L.latLngBounds(coords);
      this.map.fitBounds(wellBounds, {maxZoom: 12});

    },


    pinClickAction(pin){
      this.selectedPin = null;
      let dateResults = JSON.parse(JSON.stringify(pin.target.options.pin.result.results))
      this.selectedPin = JSON.parse(JSON.stringify(pin.target.options.pin))

      let closePins = this.isAnyPinWithSameLock(pin)
      if (closePins.length > 0){
        closePins.forEach(item=> {
            dateResults.push(item.result.results[0])
        })
      }
      let innerResult = JSON.parse(JSON.stringify(this.selectedPin))
      innerResult.result.results = dateResults;
      this.selectedPin = innerResult

    },
    isAnyPinWithSameLock(startCoords){
      // let sameCoordsPins = this.pins
      let sameCoordsPins = []
      let mainCoords = [ startCoords.target._latlng.lat , startCoords.target._latlng.lng]
      let shortestMainCoords = mainCoords.map(item => item.toFixed(4))
      mainCoords = mainCoords.map(item=> item.toString())
      this.pins.forEach(pin => {
        if (mainCoords[0] !== pin.location[0] && mainCoords[1] !== pin.location[1]){
            let shortPinCoords = pin.location.map(item => parseFloat(item).toFixed(4))
            if (shortestMainCoords[0] === shortPinCoords[0] && shortestMainCoords[1] === shortPinCoords[1]){
              pin.push(sameCoordsPins)
            }
          }
      })
      return sameCoordsPins
    },

    async setCardImage() {
      // setTimeout(() => {
      //   leafletImage(this.map, (err, canvas) => {
      //     // now you have canvas
      //     // example thing to do with that canvas:
      //     var img = document.createElement('img');
      //     var dimensions = this.map.getSize();
      //     img.width = dimensions.x;
      //     img.height = dimensions.y;
      //     img.src = canvas.toDataURL();
      //     document.getElementById('images').innerHTML = '';
      //     document.getElementById('images').appendChild(img);
      // });
      // }, 1500);
      let overridedPluginOptions = {
                mimeType: 'image/jpeg'
                }
      
      const screenShoter = new SimpleMapScreenshoter().addTo(this.map);
      this.setBounds().then( () => {
        screenShoter.takeScreen('image', overridedPluginOptions).then(image => {
          this.$emit('setSchemaPic', image);
          // setTimeout(() => {
          //   this.$emit('setSchemaPic', image) 
          // }, 2000)
        })
      })
      // this.map.on('load', () => {
      // })
    },
    setCorrectDate(date){
      let text;
      if (Number.isInteger(date)){
        text = moment(date, 'X').format('DD.MM.YYYY, HH:mm')
      } else if (date.length === 10 && date.includes('-')){
        text = moment(date, 'YYYY-MM-DD').isValid()
            ? moment(date, 'YYYY-MM-DD').format('HH:mm') === '00:00' ?
                moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY') :
                moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY HH:mm')
            : date

      } else if(date.length === 10 && date.includes('.')) {
        text = moment(date, 'DD.MM.YYYY').isValid()
            ? moment(date, 'DD.MM.YYYY').format('HH:mm') === '00:00' ?
                moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') :
                moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
            : date
      } else {
        if (!moment(date).isValid()){
          text = date;
          if(moment(date, 'DD.MM.YY HH:mm:ss').isValid()) {
            text = moment(date, 'DD.MM.YY HH:mm:ss').format('DD.MM.YYYY HH:mm');
          }
        } else {
          text = moment(date).format('HH:mm') === '00:00' ?
              moment(date).format('DD.MM.YYYY') :
              moment(date).format('DD.MM.YYYY HH:mm');
        }
      }
      return text;
    }
  },
  async mounted() {
  await this.initMap();
  await this.setFigures();
  await this.setPins();
  // await this.setCardImage();
    if (this.pins.length > 0){
      this.setCirclesLocator()
    }
},
}
</script>

<style>
.leaflet-control-simpleMapScreenshoter.leaflet-control {
  display: none;
}
</style>
<style lang="scss">
.tooltipTitle{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 8px 12px;
  background-color: rgb(237, 237, 237);
  z-index: 2;
}
.mapTooltip{
  background-color: white;
  border: 1px solid #626161;
  height: 380px;
  width: 30vh;
  min-width: 350px;
  position: absolute;
  z-index: 1001;
  top: 10px;
  left: 58px;
  border-radius: 8px
}
.section::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
.resultDate{
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  color: #363636;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
  text-align: left;
  font-size: 0.75em;
}
</style>