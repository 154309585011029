<template>
  <div class="pagination-container">
    <b-pagination
        v-if="totalRecords > 10"
        :disabled="!loadedStatus"
        :total-rows="totalRecords"
        use-router
        v-model="currentPage"
        first-number
        last-number
        :per-page="perPage"
        limit="15"
        order="is-centered"
        @input="onPageChange"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "CommentSearchPagination",

  props: {
    totalRecords: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    loadedStatus: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  methods: {
    onPageChange() {
      this.$emit("page-changed", this.currentPage);
    },
  },
}
</script>

<style scoped>

</style>