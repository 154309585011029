<template>
  <div class="main-comment">
    <div class="d-block m-t-30">
      <div v-if="!isLoaded">
        <b-skeleton class="m-t-10" height="3rem" width="100%" v-for="skeliton in skelitons"/>
      </div>

      <div v-if="isLoaded" class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item" v-for="comment in structureComments" :key="comment.id">
          <h2 class="accordion-header" :id="'heading-' + comment.id">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    aria-expanded="false"
                    :data-bs-target="'#collapse-' + comment.id" :aria-controls="'collapse-' + comment.id">
              <div class="text-truncate d-flex align-items-center" style="max-width: 600px;">
                <b-icon
                    class="limitIcon field m-r-10"
                    icon="chat-text"
                    size="is-medium"/>
                {{ randomCommentCurrentAuthor(comment) }}
              </div>
            </button>
          </h2>
          <div :id="'collapse-' + comment.id" class="accordion-collapse collapse"
               :aria-labelledby="'heading-' + comment.id" data-bs-parent="#accordionFlushExample">
            <div class="p-0 accordion-body">
              <CommentTree :comment="comment"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3">
      <comment-search-pagination
          class="position-fixed bottom-0 m-b-40 end-0 m-r-40"
          v-if="telegramGroupComments?.search_results?.length"
          :loadedStatus="isLoaded"
          :per-page="10"
          :total-records="matches_count"
          @page-changed="nextPage($event)"
      />
    </div>

    <b-modal v-model="promptPdfActive" :title="$t('pdf.save_file')" has-modal-card centered hide-footer trap-focus :destroy-on-hide="false"
             aria-role="dialog" aria-label="Example Modal" aria-modal>
      <PdfPrompt v-bind="formProps" :filename="filename" @close="closeModal" @doc-data="resultToDocument" @pdf-data="pdfUpdateData" />
    </b-modal>
  </div>
</template>

<script>
import CommentTree from "@/components/search/CommentTree.vue";
import {Result} from '@/models/result'
import CommentSearchPagination from "@/components/search/CommentSearchPagination.vue";
import {mapGetters, mapMutations} from "vuex";
import savePdf from "@/mixins/savePdf";
import filters from "@/mixins/filters";
import imageMixin from "@/mixins/imageMixin";
import saveDoc from "@/mixins/saveDoc";

export default {
  name: "CommentHistory",

  components: {CommentSearchPagination, CommentTree},

  mixins: [savePdf, filters, imageMixin, saveDoc],

  data() {
    return {
      structureComments: [],
      authorName: '',
      isLoaded: true,
      skelitons: 6,
      matches_count: 0,
      offset: 0,
      request_id: 0,
      source_id: 0,
      currentPage: 0,
      enablePDF: true,
      filename: '',
    }
  },

  mounted() {
    this.getComments(this.$route.params.id);
  },

  computed: {
    ...mapGetters('search_comment', ['telegramGroupComments'])
  },

  methods: {
    ...mapMutations('search_comment', ['setLocalTelegramComments']),

    closeModal() {
      this.promptPdfActive = false;
    },

    async nextPage(currentPage) {
      if (currentPage === this.currentPage) return;
      this.currentPage = currentPage;

      this.telegramGroupComments.offset = this.calculateOffset(currentPage);

      if (typeof this.telegramGroupComments.offset === 'boolean') {
        await this.getComments(this.$route.params.id);
        return;
      }

      let data = {
        body: {limit: 10, offset: this.telegramGroupComments.offset},
        query: {request_id: this.request_id, source_id: this.source_id}
      };

      let dbResults = await Result.getResult(Number(this.$route.params.id));

      if (typeof dbResults.result.results[0].search_results[0].commentPaginations === 'undefined') {
        dbResults.result.results[0].search_results[0].commentPaginations = [];
      }

      if (typeof dbResults.result.results[0].search_results[0].commentPaginations[currentPage] === 'undefined') {
        this.isLoaded = false;
        await this.$store.dispatch('search_comment/getTelegramGroupCommentsPaginate', data);
        this.buildTreeComments();
        dbResults.result.results[0].search_results[0].commentPaginations[currentPage] = this.structureComments;
        await Result.updateResult(dbResults.key, dbResults.result)
      }

      setTimeout(() => {this.isLoaded = true}, 200);
      this.structureComments = dbResults.result.results[0].search_results[0].commentPaginations[currentPage];
    },

    calculateOffset(currentPage) {
      let offset = false;

      if (currentPage === 2) {
        offset = 0;
      }

      if (currentPage > 2) {
        offset = (currentPage * 10) - 10;
      }

      return offset;
    },

    async getComments(id) {
      let dbResults = await Result.getResult(Number(id));

      const dataKey = dbResults.key.split('_');
      this.authorName = dataKey[1];
      await this.setLocalTelegramComments(dbResults.result.results[0]);
      this.structureComments = dbResults.result.results[0].search_results[0].comments;

      this.matches_count = this.telegramGroupComments.matches_count;
      this.offset = this.telegramGroupComments.offset;
      this.request_id = this.telegramGroupComments.request_id;
      this.source_id = this.telegramGroupComments.source_id;


      this.results = dbResults.result.results;
      this.results.photoSize = 100;
      this.filename = `${dataKey[0]}_${dataKey[1]}_${dataKey[2]}`;
      this.results[0].search_results = this.structureComments;
    },

    groupName(group) {
      let groupName = group.data.find(data => data.param === 'name' || data.param === 'username');
      return groupName.value;
    },

    buildTreeComments() {
      const map = {};

      this.telegramGroupComments.search_results.forEach(item => {
        item.children = [];
        map[item.id] = item;
      });

      const result = [];

      this.telegramGroupComments.search_results.forEach(item => {
        item['author'] = this.getAuthorName(item.author_id);
        item['photos'] = item['author']['photos'];
        item['data'] = item['author']['data'];

        if (item.parent_id && map[item.parent_id]) {
          map[item.parent_id].children.push(item);
        } else if (item.reply_to_id && map[item.reply_to_id]) {
          map[item.reply_to_id].children.push(item);
        } else {
          result.push(item);
        }
      });

      this.structureComments = result;
    },

    getAuthorName(authorId) {
      return this.telegramGroupComments.relative_results.authors.find(author => author.id === authorId)
    },

    randomCommentCurrentAuthor(comments) {
      let author = this.telegramGroupComments.relative_results.authors.find(
          user => user.data.some(item => item.value.toLowerCase() === this.authorName.toLowerCase())
      );

      let authorComment = {}
      if (comments.author_id === author.id) {
        authorComment = comments;
      }

      if (!Object.keys(authorComment).length) {
        authorComment = comments.children.find(comment => comment.author_id === author.id);
      }

      return authorComment?.text;
    },

  }
}
</script>

<style scoped>

</style>