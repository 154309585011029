import axios from "@/configs/axios";
import luhnAlgorithm from "@/helpers/luhnAlgorithm";
import resolveChildObject from "@/helpers/resolveChildObject";
import resolveResultObject from "@/helpers/resolveResultObject";
import resolveChildMovement from "@/helpers/resolveChildMovement";
import moment from "moment";
import _, { isUndefined, reject } from "underscore";
import { Result } from "../../models/result";
import resolveResultInitialObject from "@/helpers/resolveResultInitialObject"

function setSearchesLogin(login, state) {
    if(login) {
        let searches = {};
        if (localStorage.getItem('searches') && !Array.isArray(JSON.parse(localStorage.getItem('searches')))) {
            searches = localStorage.getItem('searches') ? JSON.parse(localStorage.getItem('searches')) : {};
        } else if (localStorage.getItem('searches') && Array.isArray(JSON.parse(localStorage.getItem('searches')))) {
            searches[login] = localStorage.getItem('searches') ? JSON.parse(localStorage.getItem('searches')) : [];
        }
        searches[login] = state.searches
        localStorage.setItem('searches', JSON.stringify(searches))
    }
}

function getSearchesLogin(login) {
    if (localStorage.getItem('searches') && Array.isArray(JSON.parse(localStorage.getItem('searches')))) {
        return JSON.parse(localStorage.getItem('searches'));
    }
    const usersObj =  localStorage.getItem('searches')
        && typeof JSON.parse(localStorage.getItem('searches'))[login] !== 'undefined'
        ? JSON.parse(localStorage.getItem('searches'))[login] : [];
    return usersObj;
}
function getSourceCosts(results){
    let temp = []
    let totalCost = 0;
    if(typeof results !== 'undefined'){
        results.forEach(result =>{
            totalCost += result.cost;
            temp.push({
                param: result.param,
                value: result.value,
                level: result.level,
                source: result.source,
                source_locales: result.source_locales,
                cost: result.cost,
                icon: result.icon,
                local: result.local
            })
        })
    }
    return {
        cost: temp,
        totalCost: totalCost
    }
}

// function makeCoordsArr(results){
//     let coordsArr = [];
//     if (results.length){
//         results.forEach(result => {
//             if (!isUndefined(result.search_results) && result.search_results && result.search_results.length){
//                 let searchResults = result.search_results.sort(function (a, b) { return a.info_date - b.info_date; })
//                 searchResults.forEach(search_result=> {
//                     if (search_result.data.length){
//                         let point = {
//                             address: undefined,
//                             coordinates: [],
//                             distance: undefined,
//                             info_date: search_result.info_date
//                         };
//                         // CHECK FOR RADIUS
//                         search_result.data.forEach(dataItem => {
//                             if (dataItem.param ===  'latitude'){
//                                 point.coordinates.push(dataItem.value)
//                             } else if (dataItem.param ===  'longitude'){
//                                 point.coordinates.push(dataItem.value)
//                             } else if (dataItem.param ===  'distance'){
//                                 point.distance = dataItem.value
//                             } else if (dataItem.param ===  'distance'){
//                                 point.distance = dataItem.value
//                             } else if (dataItem.param ===  'address'){
//                                 point.address = dataItem.value
//                             }
//                         })
//                         if (point.coordinates.length === 2){
//                             coordsArr.push(point)
//                         }
//                     }
//                 })
//             }
//         })
//     }
//     return coordsArr
// }


const state = () => ({
    searchesCount: 0,
    results: {},
    childResults: localStorage.getItem('childResults')
        && localStorage.getItem('childResults').toLowerCase() !== 'undefined'
        && JSON.parse(localStorage.getItem('childResults'))
        ? JSON.parse(localStorage.getItem('childResults')) : {},
    searches: [],
    searchesBuffer: [],
    search_params: [],
    resultsCache: {},
    searchedImage: "",
    faceSelect: {},
    loading: false,
    filteredParams: [],
    lastParam: "isdn",
    searchLimit: localStorage.getItem('searchLimit')
        && localStorage.getItem('searchLimit').toLowerCase() !== 'undefined'
        ? parseInt(localStorage.getItem('searchLimit')) : null,
    login: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).login : '',
    payload: JSON.parse(localStorage.getItem('payload')) || '',
    currentResultValue: '',
    searchesFilter: '',
    temporarySearches: {},
    searchOptions: localStorage.getItem('searchOptions') !== 'undefined' ? JSON.parse(localStorage.getItem('searchOptions')) : {},
    filteredSearchOptions: localStorage.getItem('filteredSearchOptions') && localStorage.getItem('filteredSearchOptions') !== 'undefined' 
        ? JSON.parse(localStorage.getItem('searchOptions')) : {},
    paginationEventWasTriggered: false
});

const getters = {
    results: state => !_.isUndefined(state.results.results) ? state.results.results.sort((a, b) => a.source > b.source ? 1 : -1).sort((a, b) => a.level > b.level ? 1 : -1) : [],
    meta: state => !_.isUndefined(state.results.meta) ? state.results.meta : {},
    errors: state => !_.isUndefined(state.results.meta) ? state.results.errors: [],
    params: state => state.search_params,
    resultsCount: state => state.searches.length,
    searchesCount: state => state.searchesCount,
    payload: state => state.payload,
    currentResultValue: state => state.currentResultValue,
    temporarySearches: state => state.temporarySearches,
    searchOptions: state => state.searchOptions,
    lastParam: state => state.lastParam,
    filteredSearchOptions: state => state.filteredSearchOptions,
    isFullSearch: state => Object.keys(state.results).length ? state.results.meta.payload.param === 'full_search' : false,
    isAnyFigure: state => state.results.meta.payload.location,
    paginationEventWasTriggered: state => state.paginationEventWasTriggered
};

const actions = {
    async search({ commit ,dispatch}, { payload }) {
        const innerPayload = JSON.parse(JSON.stringify(payload));
        if (innerPayload.param === 'imei' && innerPayload.value.length === 14) {
            innerPayload.value = innerPayload.value + luhnAlgorithm(innerPayload.value);
        }
        if (innerPayload.isdnImeiImsiSearch){
            delete innerPayload.isdnImeiImsiSearch
        }
        let secondarySearch;
        if (typeof innerPayload.secondarySearch !== 'undefined'){
            secondarySearch = true;
            delete innerPayload.secondarySearch
        }
        commit('increaseSearchCount');
        const controller = new AbortController();
        commit('addSearch', { ...payload, loaded: false, error: false, value: innerPayload.value, controller, secondarySearch, isdnImeiImsiSearch: payload.isdnImeiImsiSearch  })
        commit('setLastSearch', payload.param);
        commit('setInitFilterOptions');
        return new Promise((resolve, reject) => {
            axios({ url: 'search', data: innerPayload, method: 'POST', signal: controller.signal })
                .then(async (resp) => {
                    if (typeof resp.data.account !== 'undefined'){
                        dispatch('auth/updateAccount', resp.data.account , { root: true })
                    }
                    const searchResult = { result: resp.data.results, payload: innerPayload, targets: resp.data.targets, errors: resp.data.errors || [], secondarySearch, isdnImeiImsiSearch: payload.isdnImeiImsiSearch };
                    await commit('setSearchResult', searchResult);
                    await commit('setSearchesCache');
                    if(typeof resp.data.config.search_options !== 'undefined') {
                        await commit('setSearchOptions', resp.data.config.search_options);
                    }
                    await commit('stopSearchingLoading', searchResult)
                    await commit('decreaseSearchCount')
                    await resolve(resp.data.results);
                })
                .catch(async err => {
                    reject(err);
                    commit('decreaseSearchCount')
                    let message = typeof err.response !== 'undefined'
                        ? err.response.data : { message: 'some_undefined' };
                    const setPayload = { result: message, payload: innerPayload, isdnImeiImsiSearch: payload.isdnImeiImsiSearch  }
                    await commit('setError', setPayload);
                    await commit('stopSearchingLoading', setPayload)
                })
        })
    },


    async searchMovementHistory({ commit ,dispatch}, { payload }) {
        const innerPayload = JSON.parse(JSON.stringify(payload));
        const controller = new AbortController();
        return new Promise((resolve, reject) => {
            axios({ url: 'search', data: innerPayload, method: 'POST', signal: controller.signal })
                .then(async (resp) => {
                    if (typeof resp.data.account !== 'undefined'){
                        dispatch('auth/updateAccount', resp.data.account , { root: true })
                    }
                    const searchResult = { result: resp.data.results, payload: innerPayload, errors: resp.data.errors || []};
                    await commit('setSearchResult', searchResult);
                    await commit('setSearchesCache');
                    if(typeof resp.data.config.search_options !== 'undefined') {
                        await commit('setSearchOptions', resp.data.config.search_options);
                    }
                    await resolve(resp.data.results);
                })
                .catch(async err => {
                    reject(err);
                    let message = typeof err.response !== 'undefined'
                        ? err.response.data : { message: 'some_undefined' };
                    const setPayload = { result: message, payload: innerPayload }
                    await commit('setError', setPayload);
                })
        })
    },

    async getTelegramMonitorPreview({commit, dispatch}, data) {
        const innerPayload = {};

        let resp = await axios({url: 'content_search/preview', data: data,  method: 'POST'});

        if (typeof resp.data.account !== 'undefined'){
            dispatch('auth/updateAccount', resp.data.account , { root: true })
        }

        const searchResult = { result: resp.data.results, payload: innerPayload, errors: resp.data.errors || []};

        await commit('setSearchResult', searchResult);
        await commit('setSearchesCache');

        if(typeof resp.data.config.search_options !== 'undefined') {
            commit('setSearchOptions', resp.data.config.search_options);
        }

        commit('stopSearchingLoading', searchResult);

        // commit('setTelegramComments', resp.data.results)
        return resp.data;
    },

    fullSearch({ commit, dispatch }, { payload, type, screenshotId }) {
        const innerPayload = JSON.parse(JSON.stringify(payload));

        const user = JSON.parse(localStorage.getItem('user')).login;
        let keyVal = `${innerPayload.param}_${innerPayload.value}_${user}`;
        let secondarySearch;
        if (typeof innerPayload.secondarySearch !== 'undefined'){
            secondarySearch = true;
            delete innerPayload.secondarySearch
        }
        commit('increaseSearchCount');
        const controller = new AbortController();
        const timeRecord = moment().format('X');
        const value = !_.isUndefined(innerPayload.filters) ? innerPayload.filters[0].conditions[0].value + '_' + timeRecord : innerPayload.location.type + '_' + timeRecord
        commit('addSearch', { ...payload, param: 'full_search', typeSearch: type, screenshotId, loaded: false, error: false, value, controller, secondarySearch })
        commit('setLastSearch', 'full_search');
        commit('setInitFilterOptions');
        return new Promise((resolve, reject) => {
            axios({ url: 'filtered_search', data: innerPayload, method: 'POST', signal: controller.signal })
                .then((resp) => {
                    if (typeof resp.data.account !== 'undefined'){
                        dispatch('auth/updateAccount', resp.data.account , { root: true })
                    }

                    innerPayload.param = 'full_search';
                    innerPayload.value = value;
                    const searchResult = { result: resp.data.results, payload: innerPayload, targets: resp.data.targets, errors: resp.data.errors || [], secondarySearch };
                    commit('setSearchResult', searchResult);
                    commit('setSearchesCache');
                    if(typeof resp.data.config.search_options !== 'undefined') {
                        commit('setSearchOptions', resp.data.config.search_options);
                    }
                    commit('stopSearchingLoading', searchResult);
                    commit('decreaseSearchCount')
                    resolve(keyVal);
                })
                .catch(async err => {
                    innerPayload.param = 'full_search';
                    innerPayload.value = value
                    reject(err);
                    commit('decreaseSearchCount')
                    let message = typeof err.response !== 'undefined'
                        ? err.response.data : { message: 'some_undefined' };
                    const setPayload = { result: message, payload: innerPayload }
                    await commit('setError', setPayload);
                    await commit('stopSearchingLoading', setPayload)
                })
        })
    },




    geo_search({ commit, dispatch }, { payload, type, screenshotId }) {
        const innerPayload = JSON.parse(JSON.stringify(payload));
        const user = JSON.parse(localStorage.getItem('user')).login;
        let keyVal = `${innerPayload.param}_${innerPayload.value}_${user}`;
        let secondarySearch;
        if (typeof innerPayload.secondarySearch !== 'undefined'){
            secondarySearch = true;
            delete innerPayload.secondarySearch
        }
        commit('increaseSearchCount');
        const controller = new AbortController();
        const timeRecord = moment().format('X');
        const value = !_.isUndefined(innerPayload.filters) ? innerPayload.filters[0].conditions[0].value + '_' + timeRecord : innerPayload.location.type + '_' + timeRecord
        // commit('addSearch', { ...payload, param: 'geo_search_telegram', typeSearch: type, screenshotId, loaded: false, error: false, value, controller, secondarySearch })

        commit('addSearch', { ...payload, param: 'geo_search_telegram', typeSearch: type, screenshotId ,  loaded: false, error: false, value, controller })
        commit('setLastSearch', 'geo_search_telegram');
        // commit('setInitFilterOptions');
        return new Promise((resolve, reject) => {
            axios({ url: 'geo_search', data: innerPayload, method: 'POST', signal: controller.signal })
                .then((resp) => {
                    if (typeof resp.data.account !== 'undefined'){
                        dispatch('auth/updateAccount', resp.data.account , { root: true })
                    }

                    innerPayload.param = 'geo_search_telegram';
                    innerPayload.value = value;
                    const searchResult = { result: resp.data.results, payload: innerPayload, targets: resp.data.targets, errors: resp.data.errors || [], secondarySearch };
                    // const searchResult = { result: resp.data.results, payload: innerPayload }
                    commit('setSearchResult', searchResult);
                    commit('setSearchesCache');
                    // if(typeof resp.data.config.search_options !== 'undefined') {
                    //     commit('setSearchOptions', resp.data.config.search_options);
                    // }
                    commit('stopSearchingLoading', searchResult);
                    commit('decreaseSearchCount')
                    resolve(keyVal);
                })
                .catch(async err => {
                    innerPayload.param = 'geo_search_telegram';
                    innerPayload.value = value
                    reject(err);
                    commit('decreaseSearchCount')
                    let message = typeof err.response !== 'undefined'
                        ? err.response.data : { message: 'some_undefined' };
                    const setPayload = { result: message, payload: innerPayload }
                    await commit('setError', setPayload);
                    await commit('stopSearchingLoading', setPayload)
                })
        })
    },

    detalizeLocation({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url:`geo_search/service/${data.service_id}`, data: data.payload, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
        })
    },
    async cacheSpecifiedResults({ commit, dispatch, state }, {id, results}) {
        const resultFull = await Result.getResult(id);
        const key = resultFull.key;
        const result = resultFull.result;
        const oldLocations = result.results[0].search_results[0].locations;
        let locations = null;
        if (results[0].search_results){
            locations = results[0].search_results[0].locations;
        } else {
            locations = [{info_date: moment().format('X'), address: null}];
        }
        result.results[0].search_results[0].locations = oldLocations.concat(locations);
        await Result.updateResult(key, result);
        await dispatch('updateResultPush', id);
        return new Promise((resolve) => {
            resolve(true);
        })
    },

    updateSearch({ commit ,dispatch}, payload) {
        commit('increaseSearchCount');
        
        return new Promise((resolve, reject) => {
            axios({ url: 'search', data: payload, method: 'POST' })
                .then(resp => {
                    try {
                    if (typeof resp.data.account !== 'undefined'){
                        dispatch('auth/updateAccount', resp.data.account , { root: true })
                    }
                    const searchResult = { result: resp.data.results, payload: payload };
                    commit('setSearchResult', searchResult);
                    commit('stopSearchingLoading', searchResult);
                    commit('setSearchesCache');
                    commit('decreaseSearchCount')

                    resolve(resp.data.results);
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch(err => {
                    reject(err);
                    const setPayload = { result: message, payload: payloadUp };
                    commit('setError', { result: err.response.data, payload });
                    commit('stopLoading');
                    commit('decreaseSearchCount')
                })
        })
    },
    updateSearchItem({ commit  }, payload) {
        commit('setNewValueToSearch', payload)
    },
    searchFile({ commit, dispatch }, payload) {
        let innerPayload = payload
        const controller = new AbortController();
        let payloadUp = { param: 'image', loaded: false, error: false, value: payload.file + Date.now(), controller }
        commit('increaseSearchCount')
        const promise_file = new Promise((resolve, reject) => {
            axios({
                url: 'search',
                headers: {'Content-Type': 'multipart/form-data'},
                method: 'POST',
                signal: controller.signal,
                data: payload.payload }).then(async (resp) => {

                if (!_.isUndefined(resp.data.account)){
                    dispatch('auth/updateAccount', resp.data.account , { root: true })
                }
                let setPayload = {
                    payload: payloadUp,
                    parentPhoto: true
                };
                if (!_.isUndefined(resp.data.results)) {
                    setPayload['result'] = resp.data.results
                } else {
                    setPayload['face_select'] = resp.data.face_select
                }
                if (!_.isUndefined(innerPayload.secondarySearch)){
                    setPayload.secondarySearch = true;
                    delete innerPayload.secondarySearch
                }
                await commit('setSearchResult', setPayload);
                await commit('stopSearchingLoading', setPayload);
                await commit('setSearchesCache');
                commit('decreaseSearchCount')
                // commit('setSearchOptions', resp.data.config.search_options);
                const user = JSON.parse(localStorage.getItem('user')).login;
                const keyVal = `${payloadUp.param}_${payloadUp.value}_${user}`
                await resolve({data: resp.data, keyVal});
            }).catch(err => {
                reject(err)
                let message = typeof err.response !== 'undefined' ? err.response.data : { message: 'some_undefined' };
                const setPayload = { result: message, payload: payloadUp };
                commit('setError', setPayload);
                commit('stopSearchingLoading', setPayload);
                commit('decreaseSearchCount')
            })
        })
        payloadUp.promise_file = promise_file;
        payloadUp.offline = !!parseInt(Object.fromEntries(payload.payload.entries()).offline)
        commit('addSearch', payloadUp);
        commit('setInitFilterOptions');
        return promise_file;
    },
    searchByPhotoLink({ commit, dispatch}, { payload }) {
        let innerPayload = JSON.parse(JSON.stringify(payload))
        let secondarySearch;
        if (typeof innerPayload.secondarySearch !== 'undefined'){
            secondarySearch = true;
            delete innerPayload.secondarySearch
        }
        commit('increaseSearchCount');
        commit('addSearch', { ...innerPayload, loaded: false, error: false, value: innerPayload.value, secondarySearch })
        commit('setLastSearch', innerPayload.param);
        commit('setInitFilterOptions');
        return new Promise((resolve, reject) => {
            axios({ url: 'search', data: innerPayload, method: 'POST' })
                .then((resp) => {
                    if (typeof resp.data.account !== 'undefined'){
                        dispatch('auth/updateAccount', resp.data.account , { root: true })
                    }
                    let setPayload = {
                        payload: JSON.parse(JSON.stringify(payload)),
                        parentPhoto: true
                    };
                    setPayload['result'] = resp.data.results
                    commit('setSearchResult', setPayload);
                    commit('stopLoading');
                    commit('setSearchesCache');
                    commit('decreaseSearchCount')
                    resolve(resp.data.results);
                }).catch(err => {
                    reject(err)
                    let message = typeof err.response !== 'undefined' ? err.response.data : { message: 'some_undefined' };
                    commit('setError', { result: message, payload: innerPayload });
                    commit('stopLoading');
                    commit('decreaseSearchCount')
                })
        })

    },
    servicePagination({commit}, payload){
        let innerPayload = JSON.parse(JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            axios({url: `keyword_search/request/${innerPayload.search_request_id}/service/${innerPayload.service_id}`, data:  innerPayload.payload , method: 'POST' })
                .then(resp => {
                    let payload = {
                        data: resp.data,
                        payload: innerPayload
                    }
                    commit('setSearchPagination', payload );
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    sourcePagination({commit}, payload){
        let innerPayload = JSON.parse(JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            axios({url: `keyword_search/request/${innerPayload.search_request_id}/source/${innerPayload.service_id}`, data:  innerPayload.payload, method: 'POST' })
                .then(resp => {
                    let payload = {
                        data: resp.data,
                        payload: innerPayload
                    }
                    commit('setSearchPagination', payload );
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    faceRecognitionPagination({commit}, payload){
        return new Promise((resolve, reject) => {
            axios({url: `face_recognition/request/${payload.search_request_id}/service/${payload.service_id}`, data:  payload.payload, method: 'POST' })
                .then(resp => {
                    commit('setFaceRecognitionPagination', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    filteredSearchServicePagination({commit}, payload){
        let innerPayload = JSON.parse(JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            axios({url: `filtered_search/request/${payload.search_request_id}/service/${payload.service_id}`, data:  payload.payload, method: 'POST' })
                .then(resp => {
                    let payload = {
                        data: resp.data,
                        payload: innerPayload
                    }
                    commit('setFilteredSearchPagination', payload);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    filteredSearchSourcePagination({commit}, payload){
        let innerPayload = JSON.parse(JSON.stringify(payload))
        return new Promise((resolve, reject) => {
            axios({url: `filtered_search/request/${payload.search_request_id}/source/${payload.service_id}`, data:  payload.payload, method: 'POST' })
                .then(resp => {
                    let payload = {
                        data: resp.data,
                        payload: innerPayload
                    }
                    commit('setFilteredSearchPagination', payload);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },


    getSearchParams({ commit }) {
        let sp = '';
        try {
            sp = localStorage.getItem('search_params')
        } catch (e) {
            console.error(e);
        }
        if (sp && sp !== 'undefined') {
            let searchParams = JSON.parse(sp);
            searchParams.splice(8, 1);
            commit('setSearchParams', searchParams);
        } else {
            return new Promise((resolve, reject) => {
                axios({ url: 'config', data: {}, method: 'GET' })
                    .then(resp => {
                        const params = Object.keys(resp.data.search_options);
                        commit('setSearchParams', params);
                        commit('setSearchOptions', resp.data.search_options);
                        if(typeof resp.data.filtered_search_options !== 'undefined') {
                            commit('setFilteredSearchOptions', resp.data.filtered_search_options);
                        }
                        try {
                            localStorage.setItem('search_params', JSON.stringify(params));
                        } catch (e) {
                            console.error(e);
                        }
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('setSearchParams', []);
                        reject(err)
                    })
            })
        }
    },
    forceUpdateSearchParams({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: 'config', data: {}, method: 'GET' })
                .then(resp => {
                    const params = Object.keys(resp.data.search_options);
                    commit('setSearchParams', params);
                    commit('setSearchOptions', resp.data.search_options);
                    if(typeof resp.data.filtered_search_options !== 'undefined') {
                        commit('setFilteredSearchOptions', resp.data.filtered_search_options);
                    }
                    // commit('setDataAndAlgorithms', {
                    //     params: resp.data.params,
                    //     search_options: resp.data.search_options
                    // });
                    try {
                        localStorage.setItem('search_params', JSON.stringify(params));
                    } catch (e) {
                        console.error(e);
                    }
                    resolve(resp);
                })
                .catch(err => {
                    commit('setSearchParams', []);
                    reject(err)
                })
        })
    },
    initSearchList({ state, commit }) {
        try {
            let searchList =  getSearchesLogin(state.login);
            commit('setInitSearchList', searchList);
            commit('setSearchesBuffer', searchList);
        } catch (e) {
            console.error(e);
        }
    },
    updateResult({ commit }, payload) {
        commit('updateResultMutation', payload)
    },
    updateResultPush({ commit }, id) {
        return new Promise((resolve, reject) => {
            Result.getResult(id).then(res => {
                if (typeof res !== 'undefined'){
                    commit('updateResultMutationPush', res.result);
                }
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },
    updateSource({commit}, payload){
        return new Promise((resolve, reject) => {
            axios({ url: `search/${payload.service_id}`, data: payload.payload, method: 'POST' })
                .then(resp => {
                    commit('updateSourceMutation', {data: resp.data, payload: payload});
                    resolve(resp);
                }).catch(err => {
                    reject(err);
                })
        })
    },
    initSearchResults({ commit }) {
        return new Promise(async (resolve) => {
            try {
                // let searchResults = await Result.getResults();
                // commit("setInitSearchResults", searchResults);
                resolve();
            } catch (e) {
                console.error(e);
            }
        })
    },
    removeResult({ commit }, payload) {
        commit('setRemoveResult', payload);
        commit('setRemoveChildrenResult', payload);
        commit('setSearchesBufferClear');
        commit('setInitFilterOptions');
        return new Promise(resolve => resolve(true))
    },
    removeChild({ commit }, payload) {
        commit('setRemoveChild', payload)
        return new Promise((resolve) => resolve(true))
    },
    faceSearchBlob({commit}, payload) {
        const request = new Promise((resolve, reject) => {
          commit('increaseSearchCount');
          axios({url: 'search', data: payload.data, method: 'POST'})
              .then(resp => {
                  commit('setSearchedImage', payload.payload.value);
                  if (resp.data.face_select) {
                      commit('setFaceSelect', resp.data.face_select);
                      commit('decreaseSearchCount');
                  }
                  commit('removeTemporary', { payload: payload.payload, data: resp.data })
                  resolve(resp.data)
              })
              .catch(err => {
                  commit('stopLoading');
                  commit('decreaseSearchCount');
                  commit('removeTemporary', {
                      payload: payload.payload, data: {
                          results: [],
                          err: "true",
                      }
                  })
                  resolve({
                      results: [],
                      err: "true",
                  })
                  reject(err);
              })
          });
        commit('setTemporary', {
            payload: payload.payload,
            request
        });
      return request;
    },
    faceSearch({ commit }, payload) {
        const request = new Promise((resolve, reject) => {
            commit('increaseSearchCount');
             axios({ url: 'search', data: payload.payload, method: 'POST' })
                .then(resp => {
                    commit('setSearchedImage', payload.value);
                    if (resp.data.face_select) {
                        commit('setFaceSelect', resp.data.face_select);
                    }
                    resolve(resp.data)
                    commit('decreaseSearchCount');
                    commit('removeTemporary', { payload: payload.payload, data: resp.data })
                    
                })
                .catch(err => {
                    commit('stopLoading');
                    commit('decreaseSearchCount');
                    commit('removeTemporary', {
                        payload: payload.payload, data: {
                            results: [],
                            err: "true",
                        }
                    })
                    resolve({
                        results: [],
                        err: "true",
                    })
                    reject(err);
                });
        });

        commit('setTemporary', {
            payload: payload.payload,
            request
        });
        return request;
    },
    changeLoading({ commit }, loading) {
        commit('setLoading', loading)
        commit('setInitFilterOptions');
    },
    cacheWithResults({ commit, dispatch, state }, data) {
        let results = data.data.results
        let child = {};
        let cost = getSourceCosts(results)
        const user = JSON.parse(localStorage.getItem('user')).login;
        if (results.length) {
            if (results.length && data.type === "results") {
                child = {
                    parent: data.meta.parent ? data.meta.parent : data.meta.key,
                    child: {
                        index: 0,
                        keyVal: `${results[0].param}_${data.payload.value}_${user}`,
                        param: results[0].param,
                        value: data.payload.value,
                        date: new Date().getTime(),
                        loaded: true,
                        error: false,
                        faceResults: typeof data.data.faceResults !== 'undefined' ? data.data.faceResults : '',
                        payload: data.data.payload,
                        cost: cost.cost,
                        totalCost: cost.totalCost
                    },
                    results: results
                }
                
            } else {
                child = {
                    parent: data.meta.key,
                    child: {
                        index: data.data.face_select.face_boxes[0].index,
                        keyVal: `${results[0].param}_${results[0].value}_${user}_${data.data.face_select.face_boxes[0].index}`,
                        param: results[0].param,
                        value: results[0].value,
                        date: new Date().getTime(),
                        loaded: true,
                        error: false,
                        faceResults: typeof data.data.faceResults !== 'undefined' ? data.data.faceResults : '',
                        face_select: data.data.face_select,
                        payload: data.data.payload,
                        cost: cost.cost,
                        totalCost: cost.totalCost
                    },
                    results: results
                };
            }
            setTimeout(() => {
                dispatch('setPushChild', child).then(async () => {
                    commit('setCacheResults');
                    commit('stopLoadingChild', child);
                    if (!_.isUndefined(state.results.meta)){
                        await Result.updateResult(state.results.meta.key, state.results)
                    }
                })
            }, 100);
        }
    },
    cacheWithResultsChild({ commit, dispatch, state }, data) {
        let results = data.data
        let child = {};

        let cost = getSourceCosts(results)

        // let coordsArr;
        // if (results.length){
        //     coordsArr = makeCoordsArr(results);
        // }
        const timestamp = moment().format('X');
        const user = JSON.parse(localStorage.getItem('user')).login;
        let keyVal;

        if ( typeof data.payload.sources !== 'undefined' && data.payload.sources.includes('TelegramGeo')){
            keyVal = 'movement_history_'+`${data.payload.value}_${user}`;
        } else {
            const prefixName = (() => {
                if(isUndefined(results[0].param) && data.type === 'detalizeLocation')  {
                    return 'specify_location';
                }
            })();

            keyVal = !isUndefined(results[0].param) ? `${results[0].param}_${data.payload.value}_${user}`: `${prefixName}_${timestamp}_${user}`;
        }

        if(data.payload.childKey) {
            keyVal = `${keyVal}_${data.payload.childKey}`;
        }

        if (results.length) {
            child = {
                parent: data.parentKey,
                child: {
                    type: data.type,
                    index: 0,
                    keyVal,
                    param: results[0].param,
                    value: data.payload.value,
                    date: new Date().getTime(),
                    loaded: true,
                    error: false,
                    payload: data.payload,
                    // coordsArr: coordsArr,
                    cost: cost.cost,
                    totalCost: cost.totalCost
                },
                results: results
            }
        }

        setTimeout(() => {
            dispatch('setPushChild', child).then(async () => {
                commit('setCacheResults');

                if (data.type !== "telegram_monitor_preview") commit('stopLoadingChild', child);

                if (!_.isUndefined(state.results.meta)){
                    await Result.updateResult(state.results.meta.key, state.results)
                }
            })
        }, 100);
    },





    setPushChild({commit}, child) {
        return new Promise(resolve => {
            child.resolve = resolve;
            commit('pushChild', child);
        })
    },
    updateResultChild({ commit, state }, payload) {
        return new Promise(async (resolve, reject) => {
            const res = await Result.getResultByKey(payload)
            await resolve(res)
        })
    },
    filterResult({ commit }, payload) {
        if (payload) {
            commit('setFilterResult', payload)
        } else {
            commit('setSearchesBufferClear')
        }
    },
    clearCacheResults({ commit }) {
        commit('setClearCache');
    },
    initFilterOptions({ commit }) {
        commit('setInitFilterOptions');
    },
    cacheResultsImages({ commit }) {
        commit('setCacheResultsObject');
    },
    addFacesToSearchResults({ commit }, payload) {
        commit('addFacesToSearchResultsMutation', payload)
    },
    addFacesSearchCount({ commit }, payload) {
        commit('addFacesSearchCountMutation', payload) 
    },
    setLustSelectedSearchParam({ commit }, payload) {
        commit('setLastSearch', payload)
    },
    addPhotoToSearchResult({ commit }, payload) {
        commit('addPhotoToSearchResultMutation', payload)
    },
    async setRemoveSourceResult({ commit }, payload) {
        await commit("setRemoveSourceResultMutation", payload)
        await commit('stopSearchingLoading', payload)
    },
    async setRemoveSelectedResult({ commit }, payload ){
        await commit("setRemoveSelectedResultMutation", payload)
        await commit('stopSearchingLoading', payload)
    },
    async setRemoveSelectedResults({ commit} , payload){
        await commit("setRemoveSelectedResultsMutation", payload)
        await commit('stopSearchingLoading', payload)
    },
    async setRemoveResultOnlineAccount({ commit }, payload) {
        await commit("setRemoveResultOnlineAccountMutation", payload)
        const child = !payload.meta.parent ? false : true;
        if(!child) {
            await commit('stopSearchingLoading', payload)
        } else {
            await commit('stopLoadingChild', payload.out)
        }
        
    },
    async setRemoveResultLocal({ commit }, payload) {
        await commit("setRemoveResultLocalMutation", payload)
        const child = !payload.meta.parent ? false : true;
        if(!child) {
            await commit('stopSearchingLoading', payload)
        } else {
            await commit('stopLoadingChild', payload.out)
        }
    },
    async removePhotoSource({ commit }, payload){
        await commit("removePhotoSourceMutation", payload)
        const child = !payload.meta.parent ? false : true;
        if(!child) {
            await commit('stopSearchingLoading', payload)
        } else {
            await commit('stopLoadingChild', payload.out)
        }
    },
    clearLogin({ commit }) {
        commit('clearLoginMutation');
    },
    setLogin({ commit }) {
        commit('setLoginMutation');
    },
    updatePayload({ commit }, payload) {
        commit('setPayload', payload);
    },
    getSearchesFromCache({ commit, dispatch }) {
        commit('setSearchesFromCache');
        dispatch('initSearchList')
    },
    setCurrentResultValue({ commit }, payload) {
        commit('setCurrentResultKey', payload)
    },
    crearTempResults({ commit }) {
        commit('removeAllTemporary');
    },
    async getChildResult(context, payload) {
        
        return new Promise((resolve) => {
            resolve();
        });
    },
    async getResult(context, payload) {
        return new Promise((resolve) => {
            resolve()
        });
    },
    getFilteredSearchOptions({dispatch}) {
        if(!localStorage.getItem('filteredSearchOptions') || localStorage.getItem('filteredSearchOptions') === 'undefined') {
            dispatch('forceUpdateSearchParams')
        }
    },
    async updateRewriteResult({commit}, payload){
        return new Promise(async (resolve) => {
            const child = !payload.out.meta.parent ? false : true;
            payload.init = true;
            if(!child) {
                await commit('stopSearchingLoading', payload)
            } else {
                await commit('stopLoadingChild', {...payload.out, ...{init: true}})
            }
            await resolve();
        })
    },
    async paginationEventExist({commit}, payload){
        commit('setPaginationEventExist', payload)
    },
};

const mutations = {

    increaseSearchCount(state) {
        // state.searchesCount++
    },
    decreaseSearchCount(state) {
        // state.searchesCount--
    },
    addSearch(state, payload) {
        const user = JSON.parse(localStorage.getItem('user')).login;
        let keyVal
        if (payload.isdnImeiImsiSearch){
            keyVal = `isdnImeiImsiSearch_${payload.value}_${user}`;
        } else {
            keyVal  = `${payload.param}_${payload.value}_${user}`;
        }

        let innerPayload = Object.assign(
            {
                keyVal: keyVal,
                children: [],
                date: new Date().getTime(),
                offline: payload.offline
            },
            payload
        )
        // if (typeof payload.secondarySearch !== 'undefined'){
        //     innerPayload.secondarySearch = payload.secondarySearch
        // }

        let existIndex = state.searches.findIndex((el) => {
            return el.keyVal === keyVal
        })
        if (existIndex >= 0) {
            if (typeof state.searches[existIndex].children !== 'undefined' && state.searches[existIndex].children.length) {
                innerPayload.children =
                    JSON.parse(JSON.stringify(state.searches[existIndex].children));
            }
            state.searches = delShiftLeft(state.searches, existIndex)
        }
        state.searches.push(innerPayload);
        state.searchesBuffer = _.clone(state.searches);
        setSearchesLogin(state.login, state)
    },
    setSearchParams(state, payload) {
        if(!_.isEqual(state.searc_params, payload)) {
            state.search_params = payload;
            localStorage.setItem('search_params', JSON.stringify(payload));
        }
    },
    async setSearchBlob(state, payload) {
        const user = JSON.parse(localStorage.getItem('user')).login;
        try {
            const keyVal = `${payload.result[0].param}_${payload.payload.value}_${user}`
            const result = {
                results: payload.result ? payload.result : payload.face_select,
                meta: {
                    children: [],
                    parent: null,
                    key: `${payload.payload.param}_${payload.payload.value}_${user}`,
                },
                errors: payload.errors || []
            }
            await Result.createResult(keyVal, result);
        } catch (e) {
            console.log(e)
        }
    },
    
    // async setSearchResult(state, payload) {
    //     let cost = getSourceCosts(payload.result)
    //     const user = JSON.parse(localStorage.getItem('user')).login;
    //     let result = {};
    //     try {
    //         let value = payload.payload.value
    //         const key = `${payload.payload.param}_${payload.payload.value}_${user}`;
    //         let param = payload.result[0].param ? payload.result[0].param  : "full_search"
    //         if (payload.parentPhoto) {
    //             value = payload.payload.value;
    //         }
    //         result =
    //         {
    //             results: payload.result ? payload.result : payload.face_select,
    //             meta: {
    //                 children: [],
    //                 parent: null,
    //                 key,
    //                 targets: payload.targets,
    //                 payload: payload.payload,
    //                 secondarySearch: payload.secondarySearch,
    //                 cost: cost.cost,
    //                 totalCost: cost.totalCost ? cost.totalCost : 0
    //             },
    //             errors: payload.errors || []
    //         };
    //     } catch (e) {
    //         if (typeof payload.face_select !== "undefined") {
    //             result =
    //             {
    //                 results: [
    //                     {
    //                         error: false,
    //                         image_url: payload.face_select.image_url,
    //                         face_select: payload.face_select
    //                     }
    //                 ],
    //                 meta: {
    //                     children: [],
    //                     parent: `${payload.payload.param}_${payload.payload.value}`,
    //                     key: `${payload.payload.param}_${payload.payload.value}_${user}`,
    //                     errorName: '',
    //                     targets: payload.targets
    //                 }
    //             };
    //         } else {
    //             result =
    //             {
    //                 results: [
    //                     {
    //                         error: true,
    //                         errorName: 'Not Found'
    //                     }
    //                 ],
    //                 meta: {
    //                     children: [],
    //                     parent: null,
    //                     key: `${payload.payload.param}_${payload.payload.value}_${user}`,
    //                     error: true,
    //                     errorName: 'Not Found',
    //                     targets: payload.targets
    //                 }
    //             };
    //         }
    //     }
    //     payload.out = result;
    //     await Result.createResult(`${payload.payload.param}_${payload.payload.value}_${user}`, JSON.parse(JSON.stringify(result)));
    // },
    async setSearchResult(state, payload) {
        let tempResult = [];
        if(_.isArray(payload.result)) {
            payload.result.forEach(item => {
                let arrItem = item;
                if (item.search_results && typeof item.search_results !== 'undefined' && item.search_results.length &&  item.search_results.length > 0){
                    arrItem.search_results_count = item.search_results.length
                } else {
                    arrItem.search_results_count = 0;
                }
                tempResult.push(arrItem)
            })
        }
        let innerPayload = payload;
        innerPayload.result = tempResult



        let cost = getSourceCosts(innerPayload.result)
        const user = JSON.parse(localStorage.getItem('user')).login;
        let result = {};
        try {
            let value = innerPayload.payload.value
            let key
            if (innerPayload.isdnImeiImsiSearch){
                key = `isdnImeiImsiSearch_${innerPayload.payload.value}_${user}`;
            } else {
                key  = `${innerPayload.payload.param}_${innerPayload.payload.value}_${user}`;
            }
            // const key = `${innerPayload.payload.param}_${innerPayload.payload.value}_${user}`;
            let param = innerPayload.result[0].param ? innerPayload.result[0].param  : "full_search"
            if (innerPayload.parentPhoto) {
                value = innerPayload.payload.value;
            }
            result =
            {
                results: innerPayload.result ? innerPayload.result : innerPayload.face_select,
                meta: {
                    children: [],
                    parent: null,
                    key,
                    targets: innerPayload.targets,
                    payload: innerPayload.payload,
                    secondarySearch: innerPayload.secondarySearch,
                    cost: cost.cost,
                    totalCost: cost.totalCost ? cost.totalCost : 0
                },
                errors: innerPayload.errors || []
            };
        } catch (e) {
            if (typeof innerPayload.face_select !== "undefined") {
                result =
                {
                    results: [
                        {
                            error: false,
                            image_url: innerPayload.face_select.image_url,
                            face_select: innerPayload.face_select
                        }
                    ],
                    meta: {
                        children: [],
                        parent: `${innerPayload.payload.param}_${innerPayload.payload.value}`,
                        key: `${innerPayload.payload.param}_${innerPayload.payload.value}_${user}`,
                        errorName: '',
                        targets: innerPayload.targets
                    }
                };
            } else {
                result =
                {
                    results: [
                        {
                            error: true,
                            errorName: 'Not Found'
                        }
                    ],
                    meta: {
                        children: [],
                        parent: null,
                        key: `${innerPayload.payload.param}_${innerPayload.payload.value}_${user}`,
                        error: true,
                        errorName: 'Not Found',
                        targets: innerPayload.targets
                    }
                };
            }
        }
        payload.out = result;
        if (innerPayload.isdnImeiImsiSearch){
            await Result.createResult(`isdnImeiImsiSearch_${innerPayload.payload.value}_${user}`, JSON.parse(JSON.stringify(result)));
        } else {
            await Result.createResult(`${innerPayload.payload.param}_${innerPayload.payload.value}_${user}`, JSON.parse(JSON.stringify(result)));
        }
    },
    async setSearchResultImage(state, payload) {
        const user = JSON.parse(localStorage.getItem('user')).login;
        const result = payload.result;
        await Result.createResult(`${payload.payload.param}_${payload.payload.value}_${user}`, result);
    },
    async stopSearchingLoading(state, payload) {
        let keyVal
        const user = JSON.parse(localStorage.getItem('user')).login;

        if (payload.isdnImeiImsiSearch){
            keyVal = `isdnImeiImsiSearch_${payload.payload.value}_${user}`;
        } else {
            keyVal = payload.out.meta.key;
        }
        state.searches.map(async (item) => {
            if(payload.init) {
                item.initial = true;
            }
            if(item.keyVal === keyVal) {
                item.loaded = true;
                const objToResolve = payload.out;
                await resolveResultObject(objToResolve.results, item);
            }
        })
        await setSearchesLogin(state.login, state)
    },
    async stopLoadingChild(state, objChild) {
        const parent = !_.isUndefined(objChild.parent) ? objChild.parent : objChild.meta.parent
        const keyVal = !_.isUndefined(objChild.child) ? objChild.child.keyVal : objChild.meta.key
        state.searches.map(async item => {
            if(item.keyVal === parent) {
                if(objChild.init) {
                    item.initial = true;
                }
                item.children.map(child => {
                    if(child.keyVal === keyVal) {
                        if (['movementHistory', 'detalizeLocation'].includes(child.type)){
                            resolveChildMovement(objChild.results, child)
                        } else {
                            resolveChildObject(objChild.results, child)
                        }
                    }
                })
            }
        })
        setSearchesLogin(state.login, state)
    },
    async stopLoading(state) {
        
        const user = JSON.parse(localStorage.getItem('user')).login;
        state.searches.map(async item => {
            if (!item.loaded) {
                const res = await Result.getResultByKey(item.keyVal);
                if(!_.isUndefined(res) && !_.isUndefined(res.result)) {
                    item.loaded = true;
                    let obj = res.result;
                    if (Array.isArray(obj)) {
                        resolveResultObject(obj, item);
                    } else if (typeof obj.meta !== "undefined") {
                        resolveResultObject(obj.results, item);
                    } else {
                        resolveResultObject(obj.results, item);
                    }
                }
            } else if (item.children && item.children.length) {
                const res = await Result.getResultByKey(item.keyVal);
                item.children.map(child => {
                    let objChild = res.result;
                    if (objChild && typeof objChild.results !== 'undefined') {
                        resolveChildObject(objChild.results, child)
                    }
                })
            }
        });
        setSearchesLogin(state.login, state)
    },
    async setError(state, payload) {
        const user = JSON.parse(localStorage.getItem('user')).login;
        let key;
        if (payload.isdnImeiImsiSearch){
            key = `isdnImeiImsiSearch_${payload.payload.value}_${user}`;
        } else {
            key = `${payload.payload.param}_${payload.payload.value}_${user}`
        }
        const result =
        {
            results: [
                {
                    loaded: true,
                    error: true,
                    errorName: payload.result.message,
                }
            ],
            meta: {
                loaded: true,
                error: true,
                errorName: payload.result.message,
                key
            }
        };
        payload.out = result;
        await Result.createResult(key, result);
    },
    async setInitSearchList(state, payload) {
        state.searches = payload;
        await state.searches.map(async (item) => {
            if(!item.loaded) {
                item.loaded = true;
                item.error = true;
                item.errorName = 'interrupted_request';
                const res = await Result.getResultByKey(item.keyVal);
                // if(_.isUndefined(res)) {
                //     const result =
                //     {
                //         results: [
                //             {
                //                 loaded: true,
                //                 error: true,
                //                 errorName: 'interrupted_request',
                //             }
                //         ],
                //         meta: {
                //             loaded: true,
                //             error: true,
                //             errorName: 'interrupted_request',
                //             key: item.keyVal
                //         }
                //     };
                //     await Result.createResult(item.keyVal, result);
                //     // resolveResultObject(result, item)
                //     item.error = true;
                //     item.errorName = 'interrupted_request';
                // }
            }
        })
        await setSearchesLogin(state.login, state)
    },
    async updateResultMutation(state, payload) {
        const user = JSON.parse(localStorage.getItem('user')).login;
        let index = payload.keyVal ? payload.keyVal : `${payload.param}_${payload.value}_${user}`;
        const resultDB = await Result.getResultByKey(index)
        state.results = resultDB.result;
    },
    async updateSourceMutation(state, payload){
        let temp = state.results.results
        state.results.results.forEach((item, index )=> {
            if (item.source == payload.payload.source && item.value == payload.payload.payload.value) {
                temp[index].search_results = payload.data.results[0].search_results
            }
        })
        let key = payload.payload.key
        state.results.results = temp;
        await Result.updateResult(key, state.results);
    },
    setInitSearchResults(state, payload) {
    },

    async setRemoveChildrenResult(state, payload) {
        payload.children.forEach(child => {
            Result.deleteResult(child.keyVal);
        });
    },

    async setRemoveResult(state, payload) {
        
        await Result.deleteResult(payload.keyVal);
        state.searches = state.searches.filter(item => {
            return item.keyVal !== payload.keyVal;
        });
        state.searchesBuffer = state.searches;
        try {
            setSearchesLogin(state.login, state);
        } catch (e) {
            console.error(e);
        }
    },
    async setRemoveChild(state, payload){
        const parRes = await Result.getResultByKey(payload.result.keyVal)

        let temp = parRes.result.meta.children.filter(el=> el !== payload.child.keyVal)

        parRes.result.meta.children = temp;
        await Result.deleteResult(payload.child.keyVal);
        let tempSearches = state.searches;

        if (typeof payload.child.face_select !== 'undefined' && payload.result.param === "image"){
            tempSearches.forEach(el=> {
                if (el.keyVal === payload.result.keyVal){
                    el.face_select.face_boxes.forEach(box => {
                        if (box.index === payload.child.face_select.face_boxes[0].index){
                            delete box.childKey
                            delete box.results
                        }
                    })
                }
            })
        } else if (typeof payload.child.face_select !== 'undefined' && payload.result.param !== "image"){
            // state.resultsCache[payload.result.keyVal].results.forEach(result => {
            //     if (result.search_results){
            //         result.search_results.forEach(search => {
            //             if (search.photos.length > 0){
            //                 search.photos.forEach(photo => {
            //                     if (typeof photo.face_select !== 'undefined' &&  payload.child.value === photo.face_select.image_url){
            //                         photo.face_select.face_boxes.forEach(box => {
            //                             if (payload.child.index === box.index){
            //                                 delete box.results
            //                                 delete photo.results
            //                                 delete box.childKey
            //                             }
            //                         })
            //                     }
            //                 })
            //             }
            //         })
            //     }
            // })
        }
        tempSearches.forEach(search => {
            search.children = search.children.filter(item => {
               return item.keyVal !== payload.child.keyVal
            })
        })
        tempSearches = state.searches.filter(item => { return item.keyVal !== payload.child.keyVal; });
        state.searches = tempSearches;
        state.searchesFilter = "";
        state.searchesBuffer = state.searches;
        await Result.updateResult(payload.result.keyVal, parRes.result)
        try {
            setSearchesLogin(state.login, state);
        } catch (e) {
            console.error(e);
        }
    },
    async setImageToResult(state, payload) {
        state.results = state.results.concat(payload);
        await Result.updateResult(`${state.results[0].param}_${state.results[0].value}`, state.results)
    },
    setSearchedImage(state, payload) {
        state.searchedImage = payload
    },
    setFaceSelect(state, payload) {
        state.faceSelect = payload;
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setCacheResults(state) {
        // localStorage.setItem('resultCache', JSON.stringify(state.results))
    },
    setCacheResultsObject(state) {
        
    },
    async pushChild(state, payload) {
        let cost = getSourceCosts(payload.results)
        
        if (!state.searches.length) {
            state.searches = getSearchesLogin(state.login);
        }
        const child = {
            results: payload.results,
            meta: {
                key: payload.child.keyVal,
                children: [],
                parent: payload.parent,
                payload: payload.child.payload,
                cost: cost.cost,
                totalCost: cost.totalCost,
                // coordsArr: payload.child.coordsArr
            }
        }
        const result = await Result.getResultByKey(payload.parent)

        await result.result.meta.children.push(payload.child.keyVal);
        state.searches.map(item => {
            if (item.keyVal === payload.parent) {
                if (typeof item.children === 'undefined') {
                    item.children = [];
                }
                if(typeof _.findWhere(item.children, {keyVal: payload.child.keyVal}) === 'undefined') {
                    item.children.push(payload.child);
                }
            }
        })

        await setSearchesLogin(state.login, state);
        await Result.updateResult(payload.parent, result.result)
        await Result.createResult(payload.child.keyVal, child)
        await payload.resolve();
    },
    async setUpdateResultChild(state, payload) {
        state.childResults = await Result.getResultByKey(payload).result
    },
    setFilterResult(state, payload) {
        state.searchesFilter = payload;
        state.searchesBuffer = state.searches.filter(item => {
            return  (item.value.toLowerCase().includes(payload.toString().toLowerCase()) ||
                (typeof item.name !== 'undefined' && item.name.toLowerCase().includes(payload.toString().toLowerCase())));

        })
    },
    setSearchesBuffer(state, payload) {
        state.searchesBuffer = payload;
    },
    setSearchesBufferClear(state) {
        state.searchesFilter = "";
        state.searchesBuffer = state.searches;
    },
    setClearCache(state) {
        state.searches = [];
        state.searchesBuffer = [];
    },
    setInitFilterOptions(state) {
        state.filteredParams = [];
        for (let search of state.searches) {
            if (!state.filteredParams.includes(search.param)) {
                state.filteredParams.push(search.param)
            }
        }
    },
    async setSearchPagination(state, payload){
        let temp = state.results.results;
        // if (typeof state.results.meta.coordsArr !== 'undefined'){
        //     let results = [
        //         {
        //             search_results: payload.data.search_results
        //         }
        //     ];
        //     let coords = makeCoordsArr(results)
        //     state.results.meta.coordsArr = state.results.meta.coordsArr.concat(coords);
        // }
        let newResults = [];
        state.results.results.forEach((item, index )=> {
            if (item.source_id === payload.payload.service_id && item.value === payload.payload.value) {
                newResults = temp[index].search_results.concat(payload.data.search_results)
                temp[index].search_results = newResults;
                temp[index].search_results_count += payload.data.search_results.length;
            }
        })
        state.results.results = temp;
        await Result.updateResult(payload.payload.key, state.results)
    },
    async setFilteredSearchPagination(state, payload){
        let temp = state.results.results;
        let newResults = [];
        state.results.results.forEach((item, index )=> {
            if (item.source_id === payload.payload.service_id) {
                newResults = temp[index].search_results.concat(payload.data.search_results)
                temp[index].search_results = newResults;
                temp[index].search_results_count += payload.data.search_results.length;
            }
        })
        state.results.results = temp;
        await Result.updateResult(payload.payload.key, state.results)
    },
    setPaginationEventExist(state, payload){
        state.paginationEventWasTriggered = payload;
    },
    setLastSearch(state, param) {
        state.lastParam = param;
        setTimeout(() => {
            localStorage.setItem('lastParam', state.lastParam)
        }, 100);
    },
    setSearchLimit(state, payload) {
        state.searchLimit = payload
        localStorage.setItem('searchLimit', payload)
    },
    setSearchesCache(state) {
        setSearchesLogin(state.login, state);
    },
    setNewValueToSearch(state, payload) {
        const user = JSON.parse(localStorage.getItem('user')).login;
        const ind = state.searches.findIndex(el => {
            return el.keyVal === payload.oldPayload.keyVal;
        });
        const toChange = state.searches[ind];
        toChange.keyVal = `${payload.oldPayload.param}_${payload.newValue}_${user}`;
        toChange.value = payload.newValue;
        toChange.imageFromResults = payload.imageFromResults;
        toChange.name = undefined;
        toChange.error = undefined;
        toChange.controller = undefined;
        toChange.loaded = false;
    },
    addFacesToSearchResultsMutation(state, payload) {
        const ind = state.searches.findIndex(el => {
            return el.keyVal === payload.payload.keyVal;
        });
        state.searches[ind].face_select = payload.face_select;
        setSearchesLogin(state.login, state)

    },
    addFacesSearchCountMutation(state, payload) {
        const ind = state.searches.findIndex(el => {
            return el.keyVal === payload.payload.keyVal;
        });
        state.searches[ind].face_select.face_boxes[payload.face.index].results = payload.face.results
        setSearchesLogin(state.login, state)

    },
    addPhotoToSearchResultMutation(state, payload) {
        const ind = state.searches.findIndex(el => {
            return el.image_url === payload.image;
        });
        state.searches[ind].photo = payload;
        setSearchesLogin(state.login, state)
    },
    async setRemoveSourceResultMutation(state, payload) {
        let deleteItem;
        state.results.results.forEach(item => {
            if (item.source === payload.result.source && item.value === payload.result.value) {
                deleteItem = item;
            }
        })
        let temp = state.results.results.filter(item => item !== deleteItem)
        state.results.results = temp;
        payload.out = state.results;
        let key = payload.keyVal;
        await Result.updateResult(key, state.results)
    },

    async setRemoveSelectedResultMutation(state, payload){
        let result = state.results;
        let ind = state.results.results.findIndex(el => { return el.source === payload.result.source && el.value === payload.result.value });
        let updatedResult = result.results[ind].search_results.filter(el => el !== payload.el)
        result.results[ind].search_results = updatedResult;
        state.results = result;
        payload.out = state.results
        await Result.updateResult(payload.keyVal, result);
    },
    async setRemoveSelectedResultsMutation(state, payload){
        let result = state.results;
        let ind = state.results.results.findIndex(el => { return el.source === payload.result.source && el.level === payload.result.level; });
        let updatedResult = result.results[ind].search_results.filter(el => !el.selected)
        result.results[ind].search_results = updatedResult;
        payload.out = result
        await Result.updateResult(payload.keyVal, result)
    },

    async setRemoveResultOnlineAccountMutation(state, payload) {
        let typeOfResult;
        if (typeof payload.result.face_select === 'undefined' && !payload.meta.parent) {
            typeOfResult = state.results
        } else {
            typeOfResult = state.results
        }
        let temp;
        let ind = typeOfResult.results.findIndex(el => {
            return el.source === 'FaceRecognition';
        });
        temp = typeOfResult.results[ind].search_results.filter(resultItem => resultItem.data !== payload.infoResult.data)
        typeOfResult.results[ind].search_results = temp;
        payload.out = typeOfResult;
        await Result.updateResult(payload.meta.key, typeOfResult)
    },
    async setRemoveResultLocalMutation(state, payload) {
        let typeOfResult;
        let key;
        let deleteItem;
        if (!payload.meta.parent) {
            typeOfResult = state.results
            key = payload.meta.key;
        } else {
            typeOfResult = state.results
            key = payload.meta.key
        }
        let temp;
        let ind = typeOfResult.results.findIndex(el => {
            return el.source === payload.searchElement.source && el.local === payload.searchElement.local;
        });
        typeOfResult.results[ind].search_results.forEach(item => {
            item.photos.forEach(photo=> {
                if (photo.image === payload.searchElement.search_result.photos.image){
                    deleteItem = photo
                }
            })
        })
        typeOfResult.results[ind].search_results.forEach(item =>{
            let tempPhotos = item.photos.filter(item => item !== deleteItem)
            item.photos = tempPhotos
        })

        temp = typeOfResult.results[ind].search_results.filter(result => result.photos.length !== 0)
        typeOfResult.results[ind].search_results = temp;
        payload.out = typeOfResult;
        await Result.updateResult(key, typeOfResult)
    },
    async removePhotoSourceMutation(state, payload){
        let typeOfResult;
        if (!payload.meta.parent) {
            typeOfResult = state.results
        } else {
            typeOfResult = state.results
        }

        let tempResults = typeOfResult.results.filter(result => result.source !== payload.el.source);
        typeOfResult.results = tempResults
        payload.out = typeOfResult;
        await Result.updateResult(payload.meta.key, typeOfResult)
    },
    clearLoginMutation(state) {
        state.login = ""
        state.searches = [];
    },
    setLoginMutation(state) {
        state.login = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).login : '';
        state.searches = getSearchesLogin(state.login);
    },
    setPayload(state, payload) {
        state.payload = payload;
        localStorage.setItem('payload', JSON.stringify(state.payload));
    },
    setSearchesFromCache(state) {
        state.searches = getSearchesLogin(state.login);
    },
    setCurrentResultKey(state, payload) {
        state.currentResultValue = payload;
    },
    setTemporary(state, data) {
        state.temporarySearches[`${data.payload.param}_${data.payload.value}`] = {
            loading: true,
            request: data.request
        };
    },
    removeTemporary(state, payload) {
        state.temporarySearches[`${payload.payload.param}_${payload.payload.value}`].loading = false;
        state.temporarySearches[`${payload.payload.param}_${payload.payload.value}`].data = payload.data;
    },
    removeAllTemporary(state) {
        state.temporarySearches = {};
    },
    setSearchOptions(state, payload) {
        if(typeof _.findWhere(this.state.ui.services, {name: "InternetArchive"}) !== 'undefined') {
            payload['social_url'] = {
                "iterative":false,
                "advanced":false,
                "alternative":false,
                "cache_search":false,
                "filters":[],
                "service_types":["online"],
                "services":[
                    {
                        "name":"InternetArchive",
                        "icon_url":"https://demodata-agent.com/storage/icons/internetarchive.png",
                        "request_options":[],
                    }
                ]}
        }
        const innerPayload = JSON.parse(JSON.stringify(payload));
        // let temp = innerPayload.isdn.services.filter(item => item.name !== 'Locator')
        // innerPayload.isdn.services = temp;
        localStorage.setItem('searchOptions', JSON.stringify(innerPayload))
        state.searchOptions = innerPayload;
    },
    setFilteredSearchOptions(state, payload) {
        localStorage.setItem('filteredSearchOptions', JSON.stringify(payload));
        state.filteredSearchOptions = payload;
    },
    async updateResultMutationPush(state, result) {
        state.results =  result
    }
}

function getKeyVal(state, payload) {
    let clearKeyVal = `${payload.param}_${payload.value}`;
    let keyVal = clearKeyVal;
    if (payload.addition) {
        let keysSearches = Object.keys(state.resultsCache);
        let keysPostfixMap = keysSearches.map(item => {
            if (keyVal === item) {
                return 0;
            } else {
                let splatted = item.split(clearKeyVal + '_');
                if (splatted.length > 1) {
                    return parseInt(splatted[1])
                } else {
                    return null;
                }
            }
        })
        let keysPostfix = keysPostfixMap.filter(item => item !== null);
        if (keysPostfix.length) {
            let postfix = Math.max.apply(null, keysPostfix);
            postfix++;
            keyVal = keyVal + '_' + postfix;
        }
    }
    return keyVal;
}

function delShiftLeft(arr, index) {
    return arr.slice(0, index).concat(arr.slice(index + 1));
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}