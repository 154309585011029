
export class ImageCaching {
    constructor() {
        this.DB
    }
    initDB(objStore) {
        return new Promise((resolve, reject) => {
            if (this.DB) {
                return resolve(this.DB)
            }
            const request = window.indexedDB.open('gds', 3)
            request.onerror = e => {
                console.error('Error opening db', e)
                reject('Error')
            }
            request.onsuccess = e => {
                this.DB = e.target.result
                resolve(this.DB)
            }
            request.onupgradeneeded = e => {
                let db = e.target.result
                db.createObjectStore(objStore)
            }
        })
    }

    async checkCache(payload){
        let db = await this.initDB('images')
        let transaction = db.transaction(['images'], "readonly");
        let objectStore = transaction.objectStore('images');
        let request
        if (this.FaceSearchGlobal){
            request = objectStore.get('mini_'+payload)
        } else {
            request = objectStore.get(payload);
        }

        let result

        return new Promise(resolve => {

            request.onerror =  function (event) {
                console.error('error' + event)
            };

            request.onsuccess = function (event) {
                if (typeof event.target.result === 'undefined') {
                    result = {
                        isCached: false
                    }
                    resolve(result)
                } else {
                    result = {
                        isCached: true,
                        image: event.target.result
                    }
                    resolve(result)
                }
            }

        })
    }

    async loadNonComponentImage(link) {
        let result = await this.checkCache(link);
        if (result.isCached){
            return result.image.data;
        } else {
            // throw "Not finded image"
        }
    }

    async faceSaving(keyAfterSearch, imageLink) {
        const storageName = 'faces';
        let db = await this.initDB(storageName);
        // return new Promise(resolve => {
        //     resolve(db);
        // })
        return new Promise(resolve => {
            let trans = db.transaction([storageName], 'readwrite');
            trans.oncomplete = () => {
                resolve()
            }
            let store = trans.objectStore(storageName);
            store.add({data: keyAfterSearch }, imageLink)
        })
    }
}